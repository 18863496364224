import request from '../request'

async function getUserStatsAdmin(type) {
    return request('/user/stats/all', {
        method: 'POST',
        body: JSON.stringify({
            type
        })
    })
}

export default getUserStatsAdmin