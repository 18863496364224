import request from '../request'

async function stripePurchase(amount) {
    return request('/stripe/purchase', {
        method: 'POST',
        body: JSON.stringify({
            amount
        })
    })

}

export default stripePurchase