import request from '../request'

async function countLanguage() {
    return request('/count/language', { method: 'GET' })
}

async function countLanguageDesired() {
    return request('/count/desired_language', { method: 'GET' })
}

async function countCategory() {
    return request('/count/category', { method: 'GET' })
}

async function countCategoryDesired() {
    return request('/count/desired_category', { method: 'GET' })
}

async function countTags() {
    return request('/count/tags', { method: 'GET' })
}

async function countTagsDesired() {
    return request('/count/desired_tags', { method: 'GET' })
}

export { countLanguage, countCategory, countCategoryDesired, countLanguageDesired, countTags, countTagsDesired }