import request from '../request'

async function editPassword(password, confirm_password) {
    return request('/user/edit/password', {
        method: 'POST',
        body: JSON.stringify({
            password, confirm_password
        })
    })
}

export default editPassword