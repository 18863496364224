import request from '../request'

async function createCampaign(website_id, amount, type) {
    return request('/website/campaign/create', {
        method: 'POST',
        body: JSON.stringify({
            website_id,
            amount,
            type,
        })
    })

}

export default createCampaign