import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ownerWebsite from "../../../api/methods/ownerWebsite";
import urlEffect from "../../../utils/urlEffect";

const Sidebar = () => {
    const [website, setWebsite] = useState(undefined);

    const removeOpen = () => {
        if (window.innerWidth < 590) {
            const profile = document.querySelector('.topbar .user_wrapper');
            const dropdownProfile = profile.querySelector('.profile-link');
            dropdownProfile.classList.remove('show');

            let sidebar = document.querySelector(".sidebar");
            sidebar.classList.toggle("open");

            const topbar = document.querySelector(".topbar");
            topbar.classList.toggle("hide");

            const burger_ = document.querySelector('.burger');
            burger_.classList.replace("bx-menu-alt-right", "bx-menu");
            document.getElementById("topbar").style.color = "white";
            document.getElementById("topbar").style.justifyContent = "space-between";
            document.getElementById("topbar").style.padding = "1rem 1.5rem 1rem 1.5rem";
            document.getElementById('user_wrapper').style.display = "block";
        }
    }

    const fetchWebsites = async () => {
        const request = await ownerWebsite();
        if (request && request.success === true) {
            setWebsite(request.data);
        } else if (request && request.success === false) {
            window.localStorage.removeItem("token")
            window.location.replace("/");
        }
    };

    useEffect(() => {
        fetchWebsites();

        setInterval(() => {
            fetchWebsites();
        }, 2000);
    }, []);

    return (
        <>
            <div className={`sidebar`}>
                <Link to={"/"}>
                    <div className="logo_details">
                        <img src="https://i.imgur.com/5m0R0tB.png" alt="Logo" />
                        <div className="logo_name">
                            Adsference
                        </div>
                    </div>
                </Link>
                <ul className="sidebar_ul">
                    <li className="sub-category" onClick={() => { removeOpen() }}>
                        <Link to="/dashboard"><i className="icon fa fa-globe"></i></Link>
                        <Link to="/dashboard">All</Link>
                    </li>
                    {
                        website &&
                        website.length > 0 &&
                        website.map((websiteInfo) => {
                            let iconClass;

                            if (websiteInfo.status === "warning") {
                                iconClass = "fa fa-triangle-exclamation";
                            } else if (websiteInfo.status === "pending") {
                                iconClass = "fa fa-clock";
                            } else if (websiteInfo.status === "forbidden") {
                                iconClass = "fa fa-ban";
                            } else if (websiteInfo.status === "success") {
                                iconClass = "fa fa-square-check";
                            } else if (websiteInfo.status === "widget") {
                                iconClass = "fa fa-triangle-exclamation";
                            }

                            return (
                                <>
                                    <li className="sub-category" onClick={() => { removeOpen() }}>
                                        <Link to={`/dashboard/site/${urlEffect(websiteInfo.url)}`}>
                                            <i className={`icon ${iconClass}`}></i>
                                        </Link>
                                        <Link to={`/dashboard/site/${urlEffect(websiteInfo.url)}`}>
                                            {urlEffect(websiteInfo.url)}
                                        </Link>
                                    </li>
                                </>
                            );
                        })
                    }
                    <li className="sub-category" onClick={() => { removeOpen() }}>
                        <Link to="/dashboard/add"><i class="icon fa fa-square-plus"></i></Link>
                        <Link to="/dashboard/add">Add a new site</Link>
                    </li>
                    <hr />
                    <li className="sub-category" onClick={() => { removeOpen() }}>
                        <Link to="/dashboard/campaign"><i className="icon fa fa-play"></i></Link>
                        <Link to="/dashboard/campaign">Campaign</Link>
                    </li>
                    <li className="sub-category" onClick={() => { removeOpen() }}>
                        <Link to="/dashboard/earning"><i className="icon fa fa-money-bill-transfer"></i></Link>
                        <Link to="/dashboard/earning">Earnings</Link>
                    </li>
                    <hr />
                    <div className="title_icon" onClick={() => { removeOpen() }}>
                        <Link to="/dashboard/settings"><i className="icon fa fa-circle-user"></i></Link>
                        <Link to="/dashboard/settings" className="category">Account</Link>
                    </div>
                </ul>
            </div>
        </>
    );
};
export default Sidebar;