import request from '../request'

async function register(email, username, password, confirm_password, type) {
    return new Promise(resolve => {
        window.grecaptcha.ready(async () => {
            window.grecaptcha
                .execute("6LepKBYpAAAAAOwtLmfQH9THIUmk9GweEuoQkNHu")
                .then(async (token) => {
                    resolve(request('/register', {
                        method: 'POST',
                        body: JSON.stringify({
                            email,
                            username,
                            password,
                            confirm_password,
                            type,
                            captcha_token: token
                        })
                    }))
                })
        })
    })
}

export default register