import request from '../request'

async function getWebsiteStats(website_id, type) {
    return request('/website/stats', {
        method: 'POST',
        body: JSON.stringify({
            website_id, type
        })
    })
}

export default getWebsiteStats