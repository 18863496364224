import request from '../request'

async function cancelWebsite(website_id) {
    return request('/website/campaign/cancel', {
        method: 'POST',
        body: JSON.stringify({
            website_id
        })
    })
}

export default cancelWebsite