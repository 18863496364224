import React from 'react';

function LanguageOptions({ siteCount }) {
    const languages = [
        { code: 'all', label: 'All' },
        { code: 'ar', label: 'Arabic' },
        { code: 'zh', label: 'Chinese' },
        { code: 'en', label: 'English' },
        { code: 'fr', label: 'French' },
        { code: 'de', label: 'German' },
        { code: 'hi', label: 'Hindi' },
        { code: 'id', label: 'Indonesian' },
        { code: 'it', label: 'Italian' },
        { code: 'ja', label: 'Japanese' },
        { code: 'ko', label: 'Korean' },
        { code: 'pt', label: 'Portuguese' },
        { code: 'ru', label: 'Russian' },
        { code: 'es', label: 'Spanish' },
        { code: 'tr', label: 'Turkish' },
    ];

    return (
        <optgroup label="Langue">
            {languages.map(({ code, label }) => (
                <option key={code} value={code}>
                    {
                        siteCount ?
                            <>
                                {label} ({siteCount?.[code] ?? 0} site{siteCount?.[code] > 1 && 's'})
                            </>
                            :
                            <>
                                {label}
                            </>
                    }
                </option>
            ))}
        </optgroup>
    );
}

export default LanguageOptions;
