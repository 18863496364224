import { useEffect, useState } from "react";
import addWebsite from "../../api/methods/addWebsite";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate } from "react-router-dom";
import Seo from "../../utils/Seo";
import getWebsiteStatsAdmin from "../../api/methods/getWebsiteStatsAdmin";
import StatsChart from "./components/StatsChart";
import websiteAllAdmin from "../../api/methods/websiteAllAdmin";
import getUserStatsAdmin from "../../api/methods/getUserStatsAdmin";
import UsersChart from "./components/UsersChart";
import editWebsiteStatusAdmin from "../../api/methods/editWebsiteStatusAdmin";
import getWebsiteActivityAdmin from "../../api/methods/getWebsiteActivityAdmin";

const Admin = () => {
    const [websiteStats, setWebsiteStats] = useState([]);
    const [userStats, setUserStats] = useState([]);
    const [websiteAll, setWebsiteAll] = useState([]);
    const [websiteAllList, setWebsiteAllList] = useState([]);
    const [activeType, setActiveType] = useState('7');
    const [activeTypeUser, setActiveTypeUser] = useState('7');
    const [filteredWebsiteList, setFilteredWebsiteList] = useState([]);
    const [filterAdType, setFilterAdType] = useState("all");
    const [filterStatus, setFilterStatus] = useState("all");
    const [searchTerm, setSearchTerm] = useState("");
    const [websiteActivity, setWebsiteActivity] = useState([]);

    const handleButtonClick = (type) => {
        setActiveType(type);
        fetchWebsiteStats(type);
    };

    const handleButtonClickUser = (type) => {
        setActiveTypeUser(type);
        fetchUsersStats(type);
    };

    const fetchWebsiteStats = async (type) => {
        const request = await getWebsiteStatsAdmin(type);
        if (request && request.success === true) {
            setWebsiteStats(request.data);
        } else if (request && request.success === false) {
            setWebsiteStats(undefined)
        }
    }

    const fetchUsersStats = async (type) => {
        const request = await getUserStatsAdmin(type);
        if (request && request.success === true) {
            setUserStats(request.data);
        } else if (request && request.success === false) {
            setUserStats(undefined)
        }
    }

    const fetchWebsiteAll = async () => {
        const request = await websiteAllAdmin();
        if (request && request.success === true) {
            setWebsiteAll(request);
            setWebsiteAllList(request.data)
        } else if (request && request.success === false) {
            setWebsiteAll(undefined)
        }
    }

    const fetchWebsiteActivity = async () => {
        const request = await getWebsiteActivityAdmin();
        if (request && request.success === true) {
            setWebsiteActivity(request.data);
        }
    }

    const editWebsiteStatus = async (id, status) => {
        const request = await editWebsiteStatusAdmin(id, status);
        if (request && request.success === true) {
            fetchWebsiteAll();
        } else if (request && request.success === false) {
            toast.error(request?.message, {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        }
    }

    useEffect(() => {
        fetchWebsiteStats("7");
        fetchUsersStats("7");
        fetchWebsiteAll();
        fetchWebsiteActivity();
    }, []);

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleStatusChange = (status) => {
        setFilterStatus(status);
    };

    useEffect(() => {
        if (filterAdType === "all") {
            setFilteredWebsiteList(websiteAllList);
        } else {
            const filteredList = websiteAllList.filter(website => website.ad === filterAdType);
            setFilteredWebsiteList(filteredList);
        }
    }, [filterAdType, websiteAllList]);

    useEffect(() => {
        if (filterStatus === "all") {
            setFilteredWebsiteList(websiteAllList);
        } else {
            const filteredList = websiteAllList.filter(website => website.status === filterStatus);
            setFilteredWebsiteList(filteredList);
        }
    }, [filterStatus, websiteAllList]);

    useEffect(() => {
        const filteredList = websiteAllList.filter(website => {
            return website.url.toLowerCase().includes(searchTerm.toLowerCase());
        });
        setFilteredWebsiteList(filteredList);
    }, [searchTerm, websiteAllList]);

    return (
        <>
            <Seo dynamic={false} />
            <section className="home_section">
                <div className="container_main">
                    <div className="stats-block">
                        <h2>Statistics all websites on adsreference</h2>
                        <div className="stats">
                            <div>
                                <p>Number of views: {websiteStats ? websiteStats.totalViews : undefined}</p>
                                <p>Number of clicks: {websiteStats ? websiteStats.totalClicks : undefined}</p>
                            </div>
                            <div className="filter">
                                <button
                                    onClick={() => handleButtonClick('7')}
                                    style={{ backgroundColor: activeType === '7' ? '#007bff' : 'gray' }}>
                                    7d
                                </button>
                                <button
                                    onClick={() => handleButtonClick('14')}
                                    style={{ backgroundColor: activeType === '14' ? '#007bff' : 'gray' }}>
                                    14d
                                </button>
                                <button
                                    onClick={() => handleButtonClick('30')}
                                    style={{ backgroundColor: activeType === '30' ? '#007bff' : 'gray' }}>
                                    30d
                                </button>
                            </div>
                        </div>
                        {
                            websiteStats ? <StatsChart websiteStats={websiteStats} />
                                : <h3>No websites to display.</h3>
                        }
                    </div>
                    <div className="modifications-block">
                        <div className="modifications-right">
                            <h2>Leaderboard views (send)</h2>
                            <div class="table-container">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Views</th>
                                            <th>Sites</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {websiteActivity && websiteActivity.leaderboard_views && websiteActivity.leaderboard_views.map((item, index) => (
                                            <tr key={index}>
                                                <td>{item.views}</td>
                                                <td>{item.website_url}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="modifications-left">
                            <h2>Leaderboard clicks (send)</h2>
                            <div class="table-container">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Clicks</th>
                                            <th>Sites</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {websiteActivity && websiteActivity.leaderboard_clicks && websiteActivity.leaderboard_clicks.map((item, index) => (
                                            <tr key={index}>
                                                <td>{item.clicks}</td>
                                                <td>{item.website_url}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="modifications-block">
                        <div className="modifications-right">
                            <h2>Leaderboard views (receive)</h2>
                            <div class="table-container">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Views</th>
                                            <th>Sites</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {websiteActivity && websiteActivity.leaderboard_views_receive && websiteActivity.leaderboard_views_receive.map((item, index) => (
                                            <tr key={index}>
                                                <td>{item.views}</td>
                                                <td>{item.website_url}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="modifications-left">
                            <h2>Leaderboard clicks (receive)</h2>
                            <div class="table-container">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Clicks</th>
                                            <th>Sites</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {websiteActivity && websiteActivity.leaderboard_clicks_receive && websiteActivity.leaderboard_clicks_receive.map((item, index) => (
                                            <tr key={index}>
                                                <td>{item.clicks}</td>
                                                <td>{item.website_url}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="modifications-block">
                        <div className="modifications-right">
                            <h2>Status sites count</h2>
                            <div class="table-container">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Status</th>
                                            <th>Sites</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Success</td>
                                            <td>{websiteAll ? websiteAll.website_success : undefined}</td>
                                        </tr>
                                        <tr>
                                            <td>Pending</td>
                                            <td>{websiteAll ? websiteAll.website_pending : undefined}</td>
                                        </tr>
                                        <tr>
                                            <td>Warning</td>
                                            <td>{websiteAll ? websiteAll.website_warning : undefined}</td>
                                        </tr>
                                        <tr>
                                            <td>Forbidden</td>
                                            <td>{websiteAll ? websiteAll.website_forbidden : undefined}</td>
                                        </tr>
                                        <tr>
                                            <td>Widget</td>
                                            <td>{websiteAll ? websiteAll.website_widget : undefined}</td>
                                        </tr>
                                        <tr className="total">
                                            <td>Total</td>
                                            <td></td>
                                            <td>{websiteAll ? websiteAll.website_all : undefined}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="modifications-left">
                            <h2>Active sites count</h2>
                            <div class="table-container">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Status</th>
                                            <th>Sites</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Active</td>
                                            <td>{websiteActivity ? websiteActivity.websites_active : undefined}</td>
                                        </tr>
                                        <tr>
                                            <td>Unactive</td>
                                            <td>{websiteActivity ? websiteActivity.websites_inactive : undefined}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="stats-block">
                        <h2>Statistics all users on adsreference</h2>
                        <div className="stats">
                            <div>
                                <p>Number of users: {userStats ? userStats.totalUsers : undefined}</p>
                            </div>
                            <div className="filter">
                                <button
                                    onClick={() => handleButtonClickUser('7')}
                                    style={{ backgroundColor: activeTypeUser === '7' ? '#007bff' : 'gray' }}>
                                    7d
                                </button>
                                <button
                                    onClick={() => handleButtonClickUser('14')}
                                    style={{ backgroundColor: activeTypeUser === '14' ? '#007bff' : 'gray' }}>
                                    14d
                                </button>
                                <button
                                    onClick={() => handleButtonClickUser('30')}
                                    style={{ backgroundColor: activeTypeUser === '30' ? '#007bff' : 'gray' }}>
                                    30d
                                </button>
                            </div>
                        </div>
                        {
                            userStats ? <UsersChart userStats={userStats} />
                                : <h3>Nothing to display.</h3>
                        }
                    </div>
                    <div className="modifications-left">
                        <h2>Manage websites</h2>
                        <h3>Ad status:</h3>
                        <select value={filterAdType} onChange={(e) => setFilterAdType(e.target.value)}>
                            <option value="all">All</option>
                            <option value="yes">Ad status: Yes</option>
                            <option value="no">Ad status: No</option>
                            <option value="pending">Ad status: Pending</option>
                        </select>

                        <h3>Website Status:</h3>
                        <select value={filterStatus} onChange={(e) => handleStatusChange(e.target.value)}>
                            <option value="all">All</option>
                            <option value="success">Success</option>
                            <option value="pending">Pending</option>
                            <option value="warning">Warning</option>
                            <option value="forbidden">Forbidden</option>
                            <option value="widget">Widget</option>
                        </select>

                        <h3>Website Name:</h3>
                        <input
                            type="text"
                            placeholder="Search by website name"
                            value={searchTerm}
                            onChange={handleSearchChange}
                        />

                        <ul className="admin-website-list">
                            {filteredWebsiteList.map((website, index) => (
                                <li key={index} className="admin-website-item">
                                    <div className="ad_preview_website">
                                        <div className="header" style={{ backgroundColor: website?.color }}>
                                            <div className="buttons">
                                                <div>
                                                    <a href="https://adsreference" target="_blank"><img className="logo" src="https://i.imgur.com/5m0R0tB.png" alt="Adsreference logo"></img></a>
                                                </div>
                                                <div>
                                                    <div className="cross"></div>
                                                </div>
                                            </div>
                                            {
                                                website && website.logo ?
                                                    <div className="logo">
                                                        <img src={website.logo} alt="Your website logo" />
                                                    </div> : undefined
                                            }
                                            <div className="presentation_block">
                                                <h1>{website.title}</h1>
                                                <p>{website.description}</p>
                                            </div>
                                            <img alt="transition" className="transition" src={"https://i.imgur.com/RsqT9rn.png"} />
                                        </div>
                                        <div className="block">
                                            {
                                                website && website.firstParagraphTitle && website.firstParagraph ?
                                                    <div className="more_block">
                                                        <h3>{website?.firstParagraphTitle}</h3>
                                                        <p>{website?.firstParagraph}</p>
                                                    </div> : undefined
                                            }
                                            {
                                                website && website.secondParagraphTitle && website.secondParagraph ?
                                                    <div className="more_block">
                                                        <h3>{website?.secondParagraphTitle}</h3>
                                                        <p>{website?.secondParagraph}</p>
                                                    </div> : undefined
                                            }
                                            {
                                                website && website.thirdParagraphTitle && website.thirdParagraph ?
                                                    <div className="more_block">
                                                        <h3>{website?.thirdParagraphTitle}</h3>
                                                        <p>{website?.thirdParagraph}</p>
                                                    </div> : undefined
                                            }
                                            {
                                                website && website.urlBanner ?
                                                    <div className="banner_block">
                                                        <a href={website?.urlRedirection} target="_blank">
                                                            <img src={website?.urlBanner} alt="banner of the ad" />
                                                        </a>
                                                    </div> : undefined
                                            }
                                        </div>
                                        <div className="footer">
                                            <a className="btn btn--wide spacing-top-sm" style={{ backgroundColor: website?.color }} href={website?.urlRedirection} target="_blank">Discover<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.7071 8.70711C17.0976 8.31658 17.0976 7.68342 16.7071 7.29289L10.3431 0.928932C9.95262 0.538408 9.31946 0.538408 8.92893 0.928932C8.53841 1.31946 8.53841 1.95262 8.92893 2.34315L14.5858 8L8.92893 13.6569C8.53841 14.0474 8.53841 14.6805 8.92893 15.0711C9.31946 15.4616 9.95262 15.4616 10.3431 15.0711L16.7071 8.70711ZM0 9H16V7H0V9Z" fill="white"></path></svg></a>
                                            <div className="footer_more">
                                                <p>Powered by <a href="https://adsreference.com" target="_blank">adsreference.com</a></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="admin-website-details">
                                        <p><strong>ID:</strong> {website.id}</p>
                                        <p><strong>Website Name:</strong> {website.name}</p>
                                        <p><strong>URL:</strong> {website.url}</p>
                                        <p><strong>Ad Type:</strong> {website.ad}</p>
                                        <p><strong>Author:</strong> {website.author}</p>
                                        <p><strong>Category:</strong> {website.category}</p>
                                        <p><strong>Clicks:</strong> {website.clicks}</p>
                                        <p><strong>Coins:</strong> {website.coins}</p>
                                        <p><strong>Color:</strong> {website.color}</p>
                                        <p><strong>Desired Category:</strong> {website.desired_category}</p>
                                        <p><strong>Desired Language:</strong> {website.desired_language}</p>
                                        <p><strong>Desired Tags:</strong> {website.desired_tags}</p>
                                        <p><strong>Language:</strong> {website.language}</p>
                                        <p><strong>Owner:</strong> {website.owner}</p>
                                        <p><strong>Picture:</strong> {website.picture}</p>
                                        <p><strong>Tags:</strong> {website.tags}</p>
                                        <p><strong>Title:</strong> {website.title}</p>
                                        <p><strong>Total:</strong> {website.total}</p>
                                        <p><strong>URL Redirection:</strong> {website.urlRedirection}</p>
                                        <p><strong>Views:</strong> {website.views}</p>
                                        <p><strong>Widget:</strong> {website.widget}</p>
                                        <p><strong>Widget Frequency:</strong> {website.widgetFrequency}</p>
                                        <p><strong>Widget Position:</strong> {website.widgetPosition}</p>
                                        <p><strong>Status:</strong> {website.status}</p>
                                        <p><strong>Created At:</strong> {website.createdAt}</p>
                                    </div>
                                    <div className="button_list">
                                        <button onClick={() => { editWebsiteStatus(website.id, "success") }}>Success</button>
                                        <button onClick={() => { editWebsiteStatus(website.id, "pending") }}>Pending</button>
                                        <button onClick={() => { editWebsiteStatus(website.id, "forbidden") }}>Forbidden</button>
                                        <button onClick={() => { editWebsiteStatus(website.id, "warning") }}>Warning</button>
                                        <button onClick={() => { editWebsiteStatus(website.id, "widget") }}>Widget</button>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </section>
        </>
    );
};
export default Admin;