import request from '../request'

async function toggleWidget(id) {
    return request('/website/widget/toggle', {
        method: 'POST',
        body: JSON.stringify({
            id
        })
    })
}

export default toggleWidget