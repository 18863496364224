import { useState } from "react";
import me from "../../api/methods/me";
import { useEffect } from "react";
import editPassword from "../../api/methods/editPassword";
import editEmail from "../../api/methods/editEmail";
import editUsername from "../../api/methods/editUsername";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import deleteAccount from "../../api/methods/deleteAccount";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Link } from "react-router-dom";
import Seo from "../../utils/Seo";

const Settings = () => {
    const [user, setUser] = useState(undefined);
    const [email, setEmail] = useState(undefined);
    const [email_change, setEmailChange] = useState(false);
    const [username, setUsername] = useState(undefined);
    const [username_change, setUsernameChange] = useState(false);
    const [password, setPassword] = useState(undefined);
    const [password_change, setPasswordChange] = useState(false);
    const [confirm_password, setConfirm_password] = useState(undefined);

    const getUser = async () => {
        const request = await me();
        if (request && request.success === true) {
            setUser(request.data);
            setEmail(request.data.email)
            setUsername(request.data.username)
        } else if (request && request.success === false) {
            window.localStorage.removeItem("token")
            window.location.replace("/");
        }
    }

    const changePassword = async () => {
        const request = await editPassword(password, confirm_password);
        if (request && request.success === true) {
            getUser();
            toast.success('Password changed', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        } else if (request.message) {
            toast.error(request?.message, {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        }
    }

    const changeEmail = async () => {
        const request = await editEmail(email);
        if (request && request.success === true && !request.message) {
            getUser();
            toast.success('Email changed', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        } else if (request.message) {
            toast.error(request?.message, {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        }
    }

    const changeUsername = async () => {
        const request = await editUsername(username);
        if (request && request.success === true && !request.message) {
            getUser();
            toast.success('Username changed', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        } else if (request.message) {
            toast.error(request?.message, {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        }
    }

    const change = async () => {
        if (password_change && password && confirm_password) {
            if (password != confirm_password) {
                toast.error('Password and Confirm password field must match', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
            } else {
                const passwordPattern = /^(?=.*[0-9].*[0-9])[a-zA-Z0-9]{6}$/;
                if (!passwordPattern.test(password)) {
                    toast.error('Password must be at least 6 characters long with at least 2 numbers', {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });
                } else {
                    if (!password_change) return;
                    changePassword();
                }
            }
        } else if ((password && !confirm_password) || (!password && confirm_password)) {
            toast.error('Please complete password and confirm password field to change your password', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        }

        if (email_change) {
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailPattern.test(email)) {
                toast.error('Invalide email format', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
            } else {
                changeEmail();
            }
        }

        if (username_change) {
            if (username && username?.length > 30) {
                toast.error('Username too long', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
            } else {
                changeUsername();
            }
        }
    }

    const removeAccount = async () => {
        Swal.fire({
            title: 'Are you sure you want to delete your account?',
            text: "You won't be able to recover your data!",
            showCancelButton: true,
            confirmButtonText: 'Delete',
            cancelButtonText: `Cancel`,
            icon: "warning",
        }).then(async (result) => {
            if (result.isConfirmed) {
                toast.loading('Deleting account...', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });

                setTimeout(async () => {
                    const request = await deleteAccount();
                    if (request && request.success === true) {
                        window.location.replace("/");
                        window.localStorage.removeItem("token")
                    }
                }, 3000);
            }
        })
    }

    useEffect(() => {
        getUser();
    }, []);

    return (
        <>
            <Seo dynamic={false} />
            <section class="home_section">
                <ToastContainer
                    position="bottom-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="dark"
                />
                <div className="container_main">
                    <div className="settings-block">
                        <h2>Settings</h2>
                        <div className="settings">
                            <div>
                                <label>Username</label>
                                <input className="input"
                                    type="text"
                                    name="username"
                                    placeholder={username ? username : undefined}
                                    defaultValue={username ? username : undefined}
                                    value={username}
                                    onInput={e => {
                                        const value = e.target.value;
                                        setUsername(value);
                                        setUsernameChange(true);
                                    }}
                                    readOnly={email && email != "0" ? false : true} />
                            </div>
                            {email && email != "0" ?
                                <div>
                                    <label>Email</label>
                                    <input className="input"
                                        type="email"
                                        placeholder={email ? email : undefined}
                                        defaultValue={email ? email : undefined}
                                        value={email}
                                        onInput={e => {
                                            const value = e.target.value;
                                            setEmail(value);
                                            setEmailChange(true);
                                        }}
                                    />
                                </div> : undefined
                            }
                        </div>
                        {email && email != "0" ?
                            <div className='password_notification'>
                                <div><b className='left'>Change Password</b></div>
                                <div className='password'>
                                    <div>
                                        <label>New Password</label>
                                        <input className='input'
                                            type="password"
                                            placeholder="••••••"
                                            onInput={e => {
                                                const value = e.target.value;
                                                setPassword(value);
                                                setPasswordChange(true);
                                            }} />
                                    </div>
                                    <div>
                                        <label>Confirm<span>Password</span></label>
                                        <input className='input'
                                            type="password"
                                            placeholder="••••••"
                                            onInput={e => setConfirm_password(e.target.value)} />
                                    </div>
                                </div>
                            </div> : undefined
                        }
                        <div className='button_right'>
                            <button onClick={() => { change() }}>Save Changes</button>
                        </div>
                    </div>
                    <div className="modifications-block">
                        <div className="modifications-right">
                            <h2>Delete account</h2>
                            <p>You'll lost all your data permanently.</p>
                            <button className="red" onClick={() => { removeAccount() }}>Delete</button>
                        </div>
                        <div className="modifications-left">
                            <h2>Support</h2>
                            <p>Get fast, free help from our friendly assistants.</p>
                            <a href="mailto:contact@adsreference.com"><button>Contact Us</button></a>
                        </div>
                    </div>
                </div>
            </section >
        </>
    );
};
export default Settings;