import request from '../request';

/**
 * Save an advertisement
 * @param {Object} adDetails - Details of the advertisement
 * @param {string} adDetails.id - ID of the advertisement (if editing an existing one)
 * @param {string} adDetails.title - Title of the advertisement
 * @param {string} adDetails.description - Description of the advertisement
 * @param {string} adDetails.firstParagraphTitle - Title of the first paragraph
 * @param {string} adDetails.firstParagraph - Content of the first paragraph
 * @param {string} adDetails.secondParagraphTitle - Title of the second paragraph
 * @param {string} adDetails.secondParagraph - Content of the second paragraph
 * @param {string} adDetails.thirdParagraphTitle - Title of the third paragraph
 * @param {string} adDetails.thirdParagraph - Content of the third paragraph
 * @param {string} adDetails.urlRedirection - URL to redirect when the advertisement is clicked
 * @param {string} adDetails.urlBanner - URL of the advertisement banner
 * @param {string} adDetails.color - Color of the advertisement
 * @param {string} adDetails.widgetPosition - Position of the widget (e.g., 'left', 'right')
 * @param {string} adDetails.logo - URL of the logo associated with the advertisement
 */

async function saveAd(adDetails) {
    return request('/ad/edit', {
        method: 'POST',
        body: JSON.stringify(adDetails),
    });
}

export default saveAd;
