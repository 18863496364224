import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import Loading from './Loading';

const StatsChart = ({ websiteStats }) => {
    const [options, setOptions] = useState({});
    const [series, setSeries] = useState([]);

    useEffect(() => {
        if (websiteStats && websiteStats.stats && websiteStats.stats.clicks && websiteStats.stats.views) {
            const viewsData = websiteStats.stats.views.map((stat) => parseInt(stat[0]));
            const clicksData = websiteStats.stats.clicks.map((stat) => parseInt(stat[0]));

            const date = websiteStats.stats.views.map((stat) => stat[1]);

            const options = {
                chart: {
                    id: "Week days",
                    height: "100%",
                    width: "100%",
                    toolbar: {
                        show: true,
                        tools: {
                            download: true,
                        },
                    },
                    animations: {
                        enabled: true,
                        easing: 'easeout',
                        speed: 800,
                        animateGradually: {
                            enabled: true,
                            delay: 150
                        },
                        dynamicAnimation: {
                            enabled: true,
                            speed: 350
                        }
                    },
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    foreColor: '#fff',
                },
                xaxis: {
                    categories: date,
                    labels: {
                        style: {
                            colors: "#fff",
                        },
                    },
                },
                yaxis: {
                    labels: {
                        style: {
                            colors: "#fff",
                        },
                    },
                },
                legend: {
                    labels: {
                        colors: "#fff",
                    },
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: "55%",
                        endingShape: "rounded",
                    },
                },
                theme: {
                    mode: 'dark',
                },
            };

            const series = [
                {
                    name: "Views",
                    data: viewsData,
                },
                {
                    name: "Clicks",
                    data: clicksData,
                },
            ];

            setOptions(options);
            setSeries(series);
        }
    }, [websiteStats]);

    return (
        <div style={{ width: "100%" }}>
            {options && series.length > 0 ? (
                <ReactApexChart series={series} options={options} type="line" height={350} />
            ) : <Loading />}
        </div>
    );
};

export default StatsChart;
