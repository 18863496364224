import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import getWebsite from "../../api/methods/getWebsite";
import Swal from "sweetalert2";
import deleteWebsite from "../../api/methods/deleteWebsite";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import toggleWidget from "../../api/methods/toggleWidget";
import editWebsiteDetails from "../../api/methods/editWebsiteDetails";
import editWebsiteDesired from "../../api/methods/editWebsiteDesired";
import getAd from "../../api/methods/getAd";
import saveAd from "../../api/methods/saveAd";
import getWebsiteStats from "../../api/methods/getWebsiteStats";
import StatsChart from "./components/StatsChart";
import { countCategory, countCategoryDesired, countLanguage, countLanguageDesired, countTags, countTagsDesired } from "../../api/methods/count";
import LanguageOptions from "./components/languageOption";
import CategoryOptions from "./components/categoryOption";
import TagsOption from "./components/TagsOption.js";
import Seo from "../../utils/Seo";

const Site = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [isModal1Open, setIsModal1Open] = useState(false);
    const [isModal2Open, setIsModal2Open] = useState(false);

    const handleModal1Close = () => {
        setIsModal1Open(false);
    };

    const handleModal2Close = () => {
        setIsModal2Open(false);
    };

    const defaultAdDetails = {
        id: '',
        title: '',
        description: '',
        firstParagraphTitle: '',
        firstParagraph: '',
        secondParagraphTitle: '',
        secondParagraph: '',
        thirdParagraphTitle: '',
        thirdParagraph: '',
        urlRedirection: '',
        urlBanner: '',
        color: '',
        widgetPosition: '',
        widgetFrequency: '',
        logo: '',
    }

    const [adDetails, setAdDetails] = useState(defaultAdDetails);
    const [websiteStats, setWebsiteStats] = useState([]);
    const [activeType, setActiveType] = useState('7');

    const handleButtonClick = (type) => {
        setActiveType(type);
        fetchWebsiteStats(type);
    };

    const adPreviewStyle = {
        backgroundColor: adDetails.color
    };

    const handleInputChangePreview = (field, value) => {
        setAdDetails((prevDetails) => ({
            ...prevDetails,
            [field]: value
        }));
    };

    const fetchAd = async () => {
        const request = await getWebsite(location.pathname.split('/')[3]);
        if (request && request.success === true) {
            const request_ = await getAd(request.data.id);
            if (request_ && request_.success === true) {
                setAdDetails(request_.data);
            }
        }
    }

    const adSave = async () => {
        const request = await saveAd(adDetails);
        if (request && request.success === true) {
            fetchAd();
            toast.success('Website settings saved.', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        } else {
            toast.error(request?.message, {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        }
    }

    const [tags, setTags] = useState([]);
    const [inputValue, setInputValue] = useState('');

    const [tags1, setTags1] = useState([]);
    const [inputValue1, setInputValue1] = useState('');

    const [language, setLanguage] = useState(undefined);
    const [category, setCategory] = useState(undefined);

    const [desired_language, setLanguageDesired] = useState(undefined);
    const [desired_category, setCategoryDesired] = useState(undefined);

    const handleInputChange1 = (e) => {
        setInputValue1(e.target.value);
    };

    const handleInputKeyDown1 = (e) => {
        if (tags1.length >= 10) return;
        if (e.key === 'Enter' && inputValue1.trim() !== '') {
            const updatedTags = tags1.filter(tag => tag === inputValue1.trim());
            if (updatedTags && updatedTags.length >= 1) return
            setTags1([...tags1, inputValue1.trim()]);
            setInputValue1('');
        }
    };

    const handleTagRemove1 = (tagToRemove) => {
        const updatedTags = tags1.filter(tag => tag !== tagToRemove);
        setTags1(updatedTags);
    };

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleInputKeyDown = (e) => {
        if (tags.length >= 10) return;
        if (e.key === 'Enter' && inputValue.trim() !== '') {
            const updatedTags = tags.filter(tag => tag === inputValue.trim());
            if (updatedTags && updatedTags.length >= 1) return
            setTags([...tags, inputValue.trim()]);
            setInputValue('');
        }
    };

    const handleSelectTags = (tagsSelected, type) => {
        if (type === 1) {
            setTags1(tagsSelected);
            setIsModal1Open(false);
        }
        if (type === 2) {
            setTags(tagsSelected);
            setIsModal2Open(false);
        }
    };

    const handleTagRemove = (tagToRemove) => {
        const updatedTags = tags.filter(tag => tag !== tagToRemove);
        setTags(updatedTags);
    };

    const handleLanguageChange = (event) => {
        const selectedLanguage = event.target.value;
        setLanguage(selectedLanguage)
    };

    const handleLanguageChangeDesired = (event) => {
        const selectedLanguage = event.target.value;
        setLanguageDesired(selectedLanguage)
    };

    const handleCategoryChangeDesired = (event) => {
        const selectedCategory = event.target.value;
        setCategoryDesired(selectedCategory)
    };

    const handleCategoryChange = (event) => {
        const selectedCategory = event.target.value;
        setCategory(selectedCategory)
    };


    const [website, setWebsite] = useState([]);

    const fetchWebsite = async () => {
        const request = await getWebsite(location.pathname.split('/')[3]);
        if (request && request.success === true) {
            setWebsite(request.data);
            handleInputChangePreview('id', request.data.id)
            if (request.data.tags && request.data.tags != "0") {
                const uploadTags = request.data.tags?.split(',')?.map(tag => tag.trim());
                setTags1(uploadTags);
            } else {
                setTags1([]);
            }

            if (request.data.desired_tags && request.data.desired_tags != "0") {
                const uploadTagsDesired = request.data.desired_tags?.split(',')?.map(tag => tag.trim());
                setTags(uploadTagsDesired);
            } else {
                setTags([]);
            }
        } else if (request && request.success === false) {
            window.localStorage.removeItem("token")
            window.location.replace("/");
        }
    }

    const fetchWebsiteStats = async (type) => {
        const request = await getWebsiteStats(location.pathname.split('/')[3], type);
        if (request && request.success === true) {
            setWebsiteStats(request.data);
        } else if (request && request.success === false) {
        }
    }

    const [siteCountLanguage, setSiteCountLanguage] = useState({});
    const [siteCountLanguageDesired, setSiteCountLanguageDesired] = useState({});
    const [siteCountCategory, setSiteCountCategory] = useState({});
    const [siteCountCategoryDesired, setSiteCountCategoryDesired] = useState({});
    const [siteCountTags, setSiteCountTags] = useState({});
    const [siteCountTagsDesired, setSiteCountTagsDesired] = useState({});

    const fetchSiteCountsLanguage = async () => {
        const request = await countLanguage();
        if (request && request.success === true) {
            setSiteCountLanguage(request.data);
        }
    };

    const fetchSiteCountsLanguageDesired = async () => {
        const request = await countLanguageDesired();
        if (request && request.success === true) {
            setSiteCountLanguageDesired(request.data);
        }
    };


    const fetchSiteCountsCategoryDesired = async () => {
        const request = await countCategoryDesired();
        if (request && request.success === true) {
            setSiteCountCategoryDesired(request.data);
        }
    };

    const fetchSiteCountsCategory = async () => {
        const request = await countCategory();
        if (request && request.success === true) {
            setSiteCountCategory(request.data);
        }
    };

    const fetchSiteCountsTags = async () => {
        const request = await countTags();
        if (request && request.success === true) {
            setSiteCountTags(request.data);
        }
    };

    const fetchSiteCountsTagsDesired = async () => {
        const request = await countTags();
        if (request && request.success === true) {
            setSiteCountTagsDesired(request.data);
        }
    };


    const websiteEditDetails = async () => {
        const request = await editWebsiteDetails(website.id, language, category, tags1);
        if (request && request.success === true) {
            setWebsite(request.data)
            await fetchSiteCountsCategoryDesired()
            await fetchSiteCountsLanguageDesired()
            await fetchSiteCountsCategory()
            await fetchSiteCountsLanguage()

            toast.success('Website details changed.', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        } else {
            toast.error(request?.messahe, {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        }
    }

    const websiteEditDesired = async () => {
        const request = await editWebsiteDesired(website.id, desired_language, desired_category, tags);
        if (request && request.success === true) {
            setWebsite(request.data)

            toast.success('Website desired changed.', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        } else {
            toast.error(request?.messahe, {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        }
    }

    const widgetToggle = async (e) => {
        e.preventDefault();
        const request = await toggleWidget(website.id);
        if (request && request.success === true) {
            const updatedWebsite = { ...website };
            updatedWebsite.widget = request.data;
            setWebsite(updatedWebsite);
        }
    }

    const removeWebsite = async () => {
        Swal.fire({
            title: 'Are you sure you want to delete the website?',
            text: "You won't be able to recover website data.",
            showCancelButton: true,
            confirmButtonText: 'Delete',
            cancelButtonText: `Cancel`,
            icon: "warning",
        }).then(async (result) => {
            if (result.isConfirmed) {
                const res = await deleteWebsite(website.id);
                if (res && res.success === true) {
                    navigate(`/dashboard`);
                }
            }
        })
    }

    useEffect(() => {
        fetchWebsite();
        fetchAd();
        fetchWebsiteStats("7");
        setActiveType("7")
    }, [location.pathname]);

    useEffect(() => {
        fetchSiteCountsCategory()
        fetchSiteCountsLanguage()
        fetchSiteCountsCategoryDesired()
        fetchSiteCountsLanguageDesired()
        fetchSiteCountsTags()
        fetchSiteCountsTagsDesired()
    }, []);

    return (
        <>
            <Seo dynamic={false} />
            <section className="home_section">
                <ToastContainer
                    position="bottom-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="dark"
                />
                <div className="container_main">
                    <div className="modifications-left">
                        <h2>Website Rewards</h2>
                        <h3 className="coins">Coins: {website && website.coins ? (website.coins).toFixed(2) : 0} <i className="fa fa-coins"></i></h3>
                        <p className="widget_strong">
                            Choose your desired rewards for the visibility you've generated. You can opt for monetary compensation or free exposure for your website by redeeming your coins.
                        </p>
                        <strong>
                            <p>
                                Head to the <Link className="redirect" to={"/dashboard/earning"}>earning page</Link> to exchange your coins for money after reaching $20, or use your coins on the <Link className="redirect" to={"/dashboard/campaign"}>campaign page</Link> to enhance your website's visibility.
                            </p>
                        </strong>
                    </div>
                    <div className="modifications-block">
                        <div className="modifications-right">
                            <h2>Website status</h2>
                            <span className="info">
                                <i class="icon fa fa-circle-info"></i>
                                Show or hide the widget at any time. You won't receive coins if the widget is hidden.
                            </span>
                            <div className="toggles-flex">
                                <div className="toggles">
                                    <label className="switch" onClick={(e) => { widgetToggle(e) }}>
                                        <input type="checkbox" checked={website.widget} />
                                        <div className="slider round"></div>
                                    </label>
                                </div>
                                <span>Widget Status:</span>
                            </div>
                        </div>
                        <div className="modifications-left">
                            <h2>Widget Or Website Installation</h2>
                            {
                                (() => {
                                    switch (website?.status) {
                                        case "success":
                                            return (
                                                <p className="widget_strong">
                                                    Your website is operational, and users are visiting! Thank you for choosing our network.
                                                </p>
                                            );
                                        case "pending":
                                            return (
                                                <p className="widget_strong">
                                                    We are currently reviewing your website and ad. Please be patient. You can still edit your ad and widget settings during the review process.
                                                </p>
                                            );
                                        case "forbidden":
                                            return (
                                                <p className="widget_strong">
                                                    Unfortunately, your website cannot use our services. We apologize for any inconvenience.
                                                </p>
                                            );
                                        case "widget":
                                            return (
                                                <p className="widget_strong">
                                                    Your website is missing the required widget, or we couldn't detect it. Please navigate to the pages where the widget should be, refresh the page, and troubleshoot if necessary.
                                                </p>
                                            );
                                        case "warning":
                                            return (
                                                <p className="widget_strong">
                                                    Your advertising needs improvement; we cannot accept low-quality advertising. Please enhance the quality of your ad content.
                                                </p>
                                            );
                                        default:
                                            return "";
                                    }
                                })()
                            }
                            <div>
                                <h3>Url of the widget for your website:</h3>
                                <div className="script">
                                    <code>
                                        &lt;script src="{`https://adsreference.com/widget.js?id=${website?.id}`}" defer="defer"&gt;&lt;/script&gt;
                                    </code>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="stats-block">
                        <h2>Statistics {website && website.url ? website?.url?.split("//")[1] : undefined}</h2>
                        <div className="stats">
                            <div>
                                <p>Number of views: {websiteStats ? websiteStats.totalViews : undefined}</p>
                                <p>Number of clicks: {websiteStats ? websiteStats.totalClicks : undefined}</p>
                            </div>
                            <div className="filter">
                                <button
                                    onClick={() => handleButtonClick('7')}
                                    style={{ backgroundColor: activeType === '7' ? '#007bff' : 'gray' }}>
                                    7d
                                </button>
                                <button
                                    onClick={() => handleButtonClick('14')}
                                    style={{ backgroundColor: activeType === '14' ? '#007bff' : 'gray' }}>
                                    14d
                                </button>
                                <button
                                    onClick={() => handleButtonClick('30')}
                                    style={{ backgroundColor: activeType === '30' ? '#007bff' : 'gray' }}>
                                    30d
                                </button>
                            </div>
                        </div>
                        <StatsChart websiteStats={websiteStats} />
                    </div>
                    <div className="modifications-block">
                        <div className="modifications-left">
                            <h2>Website Details</h2>
                            <h3>Website Language:</h3>
                            <span className="info">
                                <i class="icon fa fa-circle-info"></i>
                                Choose the primary language of your users and your site. Select "All" if there is no primary language.
                            </span>
                            <select value={language ? language : website.language} onChange={handleLanguageChange}>
                                <LanguageOptions siteCount={siteCountLanguage} />
                            </select>
                            <h3>Choose a category:</h3>
                            <span className="info">
                                <i class="icon fa fa-circle-info"></i>
                                Enhance your site's discoverability by associating it with a relevant category. Help users find your content.
                            </span>
                            <select value={category ? category : website.category} onChange={handleCategoryChange}>
                                <CategoryOptions siteCount={siteCountCategory} />
                            </select>
                            <h3>Add tags to your website:</h3>
                            <span className="info">
                                <i class="icon fa fa-circle-info"></i>
                                Optimize your site's searchability by selecting accurate tags. Elevate visibility and enable users to discover your content with enhanced precision.
                            </span>
                            <strong>Enter a tag and press 'Enter' to add it. Click the button below to view a list of tags created by other owners along with their usage statistics.</strong>
                            <button className="purple"
                                onClick={() => {
                                    setIsModal1Open(true)
                                    fetchSiteCountsTags()
                                }}>
                                Discover tags list</button>
                            {isModal1Open && (
                                <TagsOption
                                    tags={siteCountTags}
                                    onClose={handleModal1Close}
                                    onSelectTags={(tagsSelected) => handleSelectTags(tagsSelected, 1)}
                                    selectedTagsOutside={tags1} />
                            )}
                            <input
                                value={inputValue1}
                                onChange={handleInputChange1}
                                onKeyDown={handleInputKeyDown1}
                                readOnly={tags1 && tags1.length >= 10 ? true : false}
                            />
                            <div className="tags-container">
                                {tags1.map((tag, index) => (
                                    <div key={index} className="tag">
                                        {tag}
                                        <span className="remove-tag" onClick={() => handleTagRemove1(tag)}>×</span>
                                    </div>
                                ))}
                            </div>
                            <div className="button_list">
                                <button onClick={() => { websiteEditDetails() }}>Save Changes</button>
                                <button className="red" onClick={() => { removeWebsite() }}>Delete Website</button>
                            </div>
                        </div>
                        <div className="modifications-left">
                            <h2>Desired Website</h2>
                            <h3>Choose a Language:</h3>
                            <span className="info">
                                <i class="icon fa fa-circle-info"></i>
                                Choose the language of users you want to attract to your site. Select "All" if there is no specific target language.
                            </span>
                            <select value={desired_language ? desired_language : website.desired_language} onChange={handleLanguageChangeDesired}>
                                <LanguageOptions siteCount={siteCountLanguageDesired} />
                            </select>
                            <h3>Choose a category:</h3>
                            <span className="info">
                                <i class="icon fa fa-circle-info"></i>
                                Select a category to finely tune where your ads appear. Choose precisely where you want your promotions showcased for optimal targeting and impact.
                            </span>
                            <select value={desired_category ? desired_category : website.desired_category} onChange={handleCategoryChangeDesired}>
                                <CategoryOptions siteCount={siteCountCategoryDesired} />
                            </select>
                            <h3>Choose tags:</h3>
                            <span className="info">
                                <i className="icon fa fa-circle-info"></i>
                                Choosing tags allows you to select specific sites where you want your ad to be displayed with even greater precision than selecting a category.
                            </span>
                            <strong>Click the button below to view a list of tags created by other owners along with their usage statistics and click on tags to select them.</strong>
                            <button className="purple"
                                onClick={() => {
                                    setIsModal2Open(true)
                                    fetchSiteCountsTagsDesired()
                                }}
                            >Discover tags list</button>
                            {isModal2Open && (
                                <TagsOption
                                    tags={siteCountTagsDesired}
                                    onClose={handleModal2Close}
                                    onSelectTags={(tagsSelected) => handleSelectTags(tagsSelected, 2)}
                                    selectedTagsOutside={tags} />
                            )}
                            {/* <input
                                placeholder="Advertising"
                                value={inputValue}
                                onChange={handleInputChange}
                                onKeyDown={handleInputKeyDown}
                                readOnly={tags && tags.length >= 10 ? true : false}
                            /> */}
                            <div className="tags-container">
                                {tags.map((tag, index) => (
                                    <div key={index} className="tag">
                                        {tag}
                                        <span className="remove-tag" onClick={() => handleTagRemove(tag)}>×</span>
                                    </div>
                                ))}
                            </div>
                            <div className="button_list">
                                <button onClick={() => { websiteEditDesired() }}>Save Changes</button>
                            </div>
                        </div>
                    </div>
                    <div className="modifications-block">
                        <div className="modifications-right">
                            <h2>Advertising Details</h2>
                            <h3>Logo url:</h3>
                            <input
                                placeholder={adDetails.logo}
                                value={adDetails.logo}
                                onChange={(e) => handleInputChangePreview('logo', e.target.value)}
                                type="url"
                            />
                            <h3>Title:</h3>
                            <input
                                placeholder="AdsReference - Boost Visibility"
                                value={adDetails.title}
                                onChange={(e) => handleInputChangePreview('title', e.target.value)}
                                maxLength={40}
                            />
                            <h3>Slogan:</h3>
                            <textarea className="slogan"
                                placeholder="Free ad network for increased exposure."
                                value={adDetails.description}
                                onChange={(e) => handleInputChangePreview('description', e.target.value)}
                                maxLength={100}
                            />
                            <h3>First paragraph title:</h3>
                            <input
                                placeholder="Why Choose AdsReference?"
                                value={adDetails.firstParagraphTitle}
                                onChange={(e) => handleInputChangePreview('firstParagraphTitle', e.target.value)}
                                maxLength={40}
                            />
                            <h3>First paragraph:</h3>
                            <textarea
                                placeholder="Unique platform for websites to collaborate and promote without cost. Enhance visibility and drive targeted traffic."
                                value={adDetails.firstParagraph}
                                onChange={(e) => handleInputChangePreview('firstParagraph', e.target.value)}
                                maxLength={120}
                            />
                            <h3>Second paragraph title:</h3>
                            <input
                                placeholder="How It Works"
                                value={adDetails.secondParagraphTitle}
                                onChange={(e) => handleInputChangePreview('secondParagraphTitle', e.target.value)}
                                maxLength={40}
                            />
                            <h3>Second paragraph:</h3>
                            <textarea
                                placeholder="Create an account, set targeting preferences, and start exchanging ad space. Engage more for increased exposure."
                                value={adDetails.secondParagraph}
                                onChange={(e) => handleInputChangePreview('secondParagraph', e.target.value)}
                                maxLength={120}
                            />
                            <h3>Third paragraph title:</h3>
                            <input
                                placeholder="Unlock the Potential"
                                value={adDetails.thirdParagraphTitle}
                                onChange={(e) => handleInputChangePreview('thirdParagraphTitle', e.target.value)}
                                maxLength={40}
                            />
                            <h3>Third paragraph:</h3>
                            <textarea
                                placeholder="Don't miss the opportunity to expand your reach. Join AdsReference and be part of a thriving community."
                                value={adDetails.thirdParagraph}
                                onChange={(e) => handleInputChangePreview('thirdParagraph', e.target.value)}
                                maxLength={120}
                            />
                            <h3>Url banner:</h3>
                            <span>Max size (465x80)</span>
                            <input
                                placeholder="https://i.imgur.com/LvltYQa.gif"
                                value={adDetails.urlBanner}
                                onChange={(e) => handleInputChangePreview('urlBanner', e.target.value)}
                                type="url"
                            />
                            <h3>Url redirection:</h3>
                            <input
                                placeholder="https://adsreference.com"
                                value={adDetails.urlRedirection}
                                onChange={(e) => handleInputChangePreview('urlRedirection', e.target.value)}
                                type="url"
                            />
                            <div className="button_list">
                                <button onClick={() => { adSave() }}>Save Changes</button>
                            </div>
                        </div>
                        <div>
                            <div className="modifications-left preview">
                                <h2>Advertising Preview</h2>
                                <div className="ad_preview_website">
                                    <div className="header" style={adPreviewStyle}>
                                        <div className="buttons">
                                            <div>
                                                <a href="https://adsreference" target="_blank"><img className="logo" src="https://i.imgur.com/5m0R0tB.png" alt="Adsreference logo"></img></a>
                                            </div>
                                            <div>
                                                <div className="cross"></div>
                                            </div>
                                        </div>
                                        {
                                            adDetails && adDetails.logo ?
                                                <div className="logo">
                                                    <img src={adDetails.logo} alt="Your website logo" />
                                                </div> : undefined
                                        }
                                        <div className="presentation_block">
                                            <h1>{adDetails.title}</h1>
                                            <p>{adDetails.description}</p>
                                        </div>
                                        <img alt="transition" className="transition" src={"https://i.imgur.com/RsqT9rn.png"} />
                                    </div>
                                    <div className="block">
                                        {
                                            adDetails && adDetails.firstParagraphTitle && adDetails.firstParagraph ?
                                                <div className="more_block">
                                                    <h3>{adDetails?.firstParagraphTitle}</h3>
                                                    <p>{adDetails?.firstParagraph}</p>
                                                </div> : undefined
                                        }
                                        {
                                            adDetails && adDetails.secondParagraphTitle && adDetails.secondParagraph ?
                                                <div className="more_block">
                                                    <h3>{adDetails?.secondParagraphTitle}</h3>
                                                    <p>{adDetails?.secondParagraph}</p>
                                                </div> : undefined
                                        }
                                        {
                                            adDetails && adDetails.thirdParagraphTitle && adDetails.thirdParagraph ?
                                                <div className="more_block">
                                                    <h3>{adDetails?.thirdParagraphTitle}</h3>
                                                    <p>{adDetails?.thirdParagraph}</p>
                                                </div> : undefined
                                        }
                                        {
                                            adDetails && adDetails.urlBanner ?
                                                <div className="banner_block">
                                                    <a href={adDetails?.urlRedirection} target="_blank">
                                                        <img src={adDetails?.urlBanner} alt="banner of the ad" />
                                                    </a>
                                                </div> : undefined
                                        }
                                    </div>
                                    <div className="footer">
                                        <a className="btn btn--wide spacing-top-sm" style={adPreviewStyle} href={adDetails?.urlRedirection} target="_blank">Discover<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.7071 8.70711C17.0976 8.31658 17.0976 7.68342 16.7071 7.29289L10.3431 0.928932C9.95262 0.538408 9.31946 0.538408 8.92893 0.928932C8.53841 1.31946 8.53841 1.95262 8.92893 2.34315L14.5858 8L8.92893 13.6569C8.53841 14.0474 8.53841 14.6805 8.92893 15.0711C9.31946 15.4616 9.95262 15.4616 10.3431 15.0711L16.7071 8.70711ZM0 9H16V7H0V9Z" fill="white"></path></svg></a>
                                        <div className="footer_more">
                                            <p>Powered by <a href="https://adsreference.com" target="_blank">adsreference.com</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modifications-left">
                                <h2>Advertising Settings</h2>
                                <h3>Color:</h3>
                                <input
                                    type="color"
                                    value={adDetails.color}
                                    onChange={(e) => handleInputChangePreview('color', e.target.value)}
                                />
                                <div className="button_list">
                                    <button onClick={() => { adSave() }}>Save Changes</button>
                                </div>
                            </div>
                            <div className="modifications-left">
                                <h2>Widget Settings</h2>
                                <h3>Widget color:</h3>
                                <p style={{ maxWidth: "392px" }}>The widget color will automatically match the advertising color. You can customize it above.</p>
                                <h3>Widget position:</h3>
                                <div className="radio-input">
                                    <input
                                        type="radio"
                                        value="right"
                                        checked={adDetails.widgetPosition === 'right'}
                                        onChange={() => handleInputChangePreview('widgetPosition', 'right')}
                                    />
                                    Right
                                </div>
                                <div className="radio-input">
                                    <input
                                        type="radio"
                                        value="left"
                                        checked={adDetails.widgetPosition === 'left'}
                                        onChange={() => handleInputChangePreview('widgetPosition', 'left')}
                                    />
                                    Left
                                </div>
                                <h3>Widget Apparition Frequency</h3>
                                <p>Configure the frequency at which the widget will automatically appear for users.</p>
                                <div className="range-input">
                                    <label htmlFor="widgetFrequency">Every {adDetails?.widgetFrequency} minutes</label>
                                    <input
                                        type="range"
                                        id="widgetFrequency"
                                        name="widgetFrequency"
                                        min="1"
                                        max="15"
                                        step="1"
                                        value={adDetails?.widgetFrequency}
                                        onChange={(e) => handleInputChangePreview('widgetFrequency', parseInt(e.target.value))}
                                    />
                                </div>
                                <div className="button_list">
                                    <button onClick={() => { adSave() }}>Save Changes</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </>
    );
};
export default Site;