import { Link } from "react-router-dom";
import ownerWebsite from "../../api/methods/ownerWebsite";
import { useEffect, useState } from "react";
import React from "react";
import ReactApexChart from "react-apexcharts";
import StatsChart from "./components/StatsChart";
import getAllStats from "../../api/methods/getAllStats";
import Seo from "../../utils/Seo";

const Index_Dashboard = () => {
    const [website, setWebsite] = useState([]);
    const [coins, setCoins] = useState(undefined);
    const [websiteStats, setWebsiteStats] = useState([]);
    const [activeType, setActiveType] = useState('7');

    const fetchWebsites = async () => {
        const request = await ownerWebsite();
        if (request && request.success === true) {
            setWebsite(request.data);
            setCoins(request.coins)
        } else if (request && request.success === false) {
            window.localStorage.removeItem("token")
            window.location.replace("/");
        }
    }

    const handleButtonClick = (type) => {
        setActiveType(type);
        fetchWebsiteStats(type);
    };

    const fetchWebsiteStats = async (type) => {
        const request = await getAllStats(type);
        if (request && request.success === true) {
            setWebsiteStats(request.data);
        } else if (request && request.success === false) {
            setWebsiteStats(undefined)
        }
    }

    useEffect(() => {
        fetchWebsites();
        fetchWebsiteStats("7");
    }, []);

    return (
        <>
            <Seo
                dynamic={true}
                title={"Adsreference Dashboard - manage your sites effectively"}
                description={"Take control of your online presence with the Adsreference Dashboard. Efficiently manage your sites, analyze performance, and optimize promotion strategies. Join now for powerful site management."}
            />
            <section className="home_section">
                <div className="container_main">
                    <div className="modifications-left">
                        <h2>Website Global Rewards</h2>
                        <h3 className="coins">Coins: {coins ? (coins).toFixed(2) : coins} <i className="fa fa-coins"></i></h3>
                        <p className="widget_strong">
                            Choose your desired rewards for the visibility you've generated. You can opt for monetary compensation or free exposure for your website by redeeming your coins. 
                        </p>
                        <strong>
                            <p>
                                Head to the <Link className="redirect" to={"/dashboard/earning"}>earning page</Link> to exchange your coins for money after reaching $20, or use your coins on the <Link className="redirect" to={"/dashboard/campaign"}>campaign page</Link> to enhance your website's visibility.
                            </p>
                        </strong>
                    </div>
                    <div className="stats-block">
                        <h2>Statistics all websites</h2>
                        <div className="stats">
                            <div>
                                <p>Number of views: {websiteStats ? websiteStats.totalViews : undefined}</p>
                                <p>Number of clicks: {websiteStats ? websiteStats.totalClicks : undefined}</p>
                            </div>
                            <div className="filter">
                                <button
                                    onClick={() => handleButtonClick('7')}
                                    style={{ backgroundColor: activeType === '7' ? '#007bff' : 'gray' }}>
                                    7d
                                </button>
                                <button
                                    onClick={() => handleButtonClick('14')}
                                    style={{ backgroundColor: activeType === '14' ? '#007bff' : 'gray' }}>
                                    14d
                                </button>
                                <button
                                    onClick={() => handleButtonClick('30')}
                                    style={{ backgroundColor: activeType === '30' ? '#007bff' : 'gray' }}>
                                    30d
                                </button>
                            </div>
                        </div>
                        {
                            websiteStats ? <StatsChart websiteStats={websiteStats} />
                                : <h3>Create a website first.</h3>
                        }
                    </div>
                    <div className="modifications-block">
                        <div className="modifications-right">
                            <h2>Your Websites</h2>
                            <div className="website_list">
                                {
                                    website &&
                                    website.length > 0 &&
                                    website.map((websiteInfo) => {
                                        let iconClass;

                                        if (websiteInfo.status === "warning") {
                                            iconClass = "fa fa-triangle-exclamation";
                                        } else if (websiteInfo.status === "pending") {
                                            iconClass = "fa fa-clock";
                                        } else if (websiteInfo.status === "forbidden") {
                                            iconClass = "fa fa-ban";
                                        } else if (websiteInfo.status === "success") {
                                            iconClass = "fa fa-square-check";
                                        } else if (websiteInfo.status === "widget") {
                                            iconClass = "fa fa-triangle-exclamation";
                                        }
                                        return (
                                            <>
                                                <li className="website_redirection">
                                                    <i className={`icon ${iconClass}`}></i>
                                                    <Link to={`/dashboard/site/${websiteInfo.url?.split('//')[1].split('/')[0]}`}>
                                                        {websiteInfo.url?.split('//')[1].split('/')[0]}
                                                    </Link>
                                                </li>
                                            </>
                                        );
                                    })
                                }
                            </div>
                            <Link to={"/dashboard/add"}><button>Add a new site</button></Link>
                        </div>
                        <div className="modifications-left">
                            <h2>Tutorial Installation</h2>
                            <p>1. Add your site <Link to={"/dashboard/add"} style={{ color: "#007bff" }}>here</Link> and add the widget on your website.</p>
                            <p>2. Setup your website with categories and tags. Setup your preference to receive users from the network.</p>
                            <p>3. Make your ad design and it's finish, see your website grow automatically.</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};
export default Index_Dashboard;