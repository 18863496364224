import request from '../request'

async function editUsername(username) {
    return request('/user/edit/username', {
        method: 'POST',
        body: JSON.stringify({
            username
        })
    })
}

export default editUsername