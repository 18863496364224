import request from '../request'

async function editWebsiteDetails(id, language, category, tags) {
    return request('/website/edit/details', {
        method: 'POST',
        body: JSON.stringify({
            id, language, category, tags
        })
    })
}

export default editWebsiteDetails