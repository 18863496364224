import { Link } from "react-router-dom";
import callback from "../../../utils/callback";
import login_discord from "../../../api/methods/login_discord";

const Nav_Landing = () => {
    const login_discord_user = async (code) => {
        const res = await login_discord(code, "DISCORD");
        if (res && res.success === true) {
            window.localStorage.setItem("token", res.token);
            if (window.localStorage && window.localStorage?.getItem("callback") && window.localStorage?.getItem("callback") === "/dashboard") {
                window.location.replace("/dashboard");
            } else {
                if (window.localStorage && window.localStorage?.getItem("callback")) {
                    window.location.replace("/callback");
                } else {
                    window.location.replace("/dashboard");
                }
            }
        } else {
            window.location.replace("/login/discord");
        }
    }

    if (window.location.pathname === "/login") {
        if (window.location.search.split("=") && window.location.search.split("=")[1]) {
            login_discord_user(window.location.search.split("=")[1]);
        }
    }

    if (window.location.pathname === "/") {
        const affiliation = window.location.search && window.location.search.split("=").length === 2 ? window.location.search.split("=")[1] : null;
        window.localStorage.setItem("affiliation", affiliation);
    }

    if (window.location.pathname === "/callback") {
        callback();
    }

    const isLogin = window.localStorage.getItem("token") ? true : false

    return (
        <>
            <nav>
                <div className="nav_position">
                    <Link to={"/"}>
                        <div className="nav_logo">
                            <img src="https://i.imgur.com/5m0R0tB.png" alt="Adsreference Logo" />
                            <h3>Adsreference</h3>
                        </div>
                    </Link>
                    <Link to={isLogin ? "/dashboard" : "/login"}>
                        <button>{isLogin ? "Dashboard" : "Login"}</button>
                    </Link>
                </div>
            </nav>
        </>
    );
};
export default Nav_Landing;