import request from '../request'

async function addWebsite(url, adDetails) {
    return new Promise(resolve => {
        window.grecaptcha.ready(async () => {
            window.grecaptcha
                .execute("6LepKBYpAAAAAOwtLmfQH9THIUmk9GweEuoQkNHu")
                .then(async (token) => {
                    resolve(request('/website/add', {
                        method: 'POST',
                        body: JSON.stringify({
                            url,
                            adDetails,
                            captcha_token: token
                        })
                    }))
                })
        })
    })
}

export default addWebsite