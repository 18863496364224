import Footer from "./layout/footer";
import Nav_Landing from "./layout/nav_landing";
import wave_top from "../../public/img/wave-top.png"
import wave_mid from "../../public/img/wave-mid.png"
import wave_bot from "../../public/img/wave-bot.png"
import share from "../../public/img/modal_share.svg"
import { Link } from "react-router-dom";
import Seo from "../../utils/Seo";

const Index = () => {
    const afq = async (element) => {
        if (!element.querySelector('.icon').classList.contains('open')) {
            const faq_open = document.querySelectorAll(".open")
            faq_open.forEach((item) => {
                item.classList.remove("open")
            })
        }

        element.querySelector('.icon').classList.toggle('open');
        element.querySelector('.faq_answer').classList.toggle('open');
    };

    const isLogin = window.localStorage.getItem("token") ? true : false

    return (
        <>
            <Seo
                dynamic={true}
                title={"Adsreference - your free network for effective site promotion"}
                description={"Discover Adsreference, a powerful free network dedicated to enhancing site promotion. Elevate your online visibility and engage a wider audience effortlessly."}
            />
            <div className="waveWrapper waveAnimation">
                <section className="main">
                    <div className="main_content">
                        <h2>
                            Adsreference - Free Network for Site Promotion
                        </h2>
                        <p>
                            Discover Adsreference, a powerful free network dedicated to enhancing site promotion. Elevate your online visibility and engage a wider audience effortlessly.
                        </p>
                        <Link to={isLogin ? "/dashboard" : "/login"} onClick={window.localStorage.setItem("callback", "/dashboard")}><button>Get Started</button></Link>
                    </div>
                    <img className="custom-hero-img" src={share} alt="Social media sharing" />
                </section>
                <div className="waveWrapperInner top">
                    <div className="wave waveTop" style={{ backgroundImage: `url(${wave_top})` }}></div>
                </div>
                <div className="waveWrapperInner mid">
                    <div className="wave waveMid" style={{ backgroundImage: `url(${wave_mid})` }}></div>
                </div>
                <div className="waveWrapperInner bottom">
                    <div className="wave waveBottom" style={{ backgroundImage: `url(${wave_bot})` }}></div>
                </div>
            </div>
            <section className="how-it-works">
                <div className="how-it-works-position">
                    <div className="how-it-works-content">
                        <div className="how-it-works-item">
                            <div className="icon-wrapper">
                                <i className="fas fa-plus icon"></i>
                            </div>
                            <h3>Add Adsreference to Your Website</h3>
                            <p>Start by integrating our AdsReference widget into your website effortlessly.</p>
                        </div>
                        <div className="how-it-works-item">
                            <div className="icon-wrapper">
                                <i className="fas fa-eye icon"></i>
                            </div>
                            <h3>Your Users Receive Non-intrusive Ads</h3>
                            <p>Users receive non-intrusive ads, helping them discover new projects you've chosen.</p>
                        </div>
                        <div className="how-it-works-item">
                            <div className="icon-wrapper">
                                <i className="fas fa-users icon"></i>
                            </div>
                            <h3>Your Site Grows Automatically</h3>
                            <p>Your site experiences automatic growth with an influx of new users.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="faq">
                <div className="faq_main">
                    <h1>Frequently Asked Questions</h1>
                    <p>
                        Here are some of our FAQs.
                    </p>
                </div>
                <div className="faq_all">
                    <div className="faq_container" onClick={(e) => afq(e.currentTarget)}>
                        <div className="faq_question" >
                            <span>What is Adsreference and how does it work?</span>
                            <i className="icon fas fa-chevron-down"></i>
                        </div>
                        <div className="faq_answer">
                            <span>Adsreference is a free online advertising exchange platform that facilitates websites in promoting each other. By displaying ads from other participating sites, you earn exposure credits. These credits can then be used to showcase your own ads on other websites within the Adsreference community.</span>
                        </div>
                    </div>
                    <div className="faq_container" onClick={(e) => afq(e.currentTarget)}>
                        <div className="faq_question">
                            <span>How do I get started with Adsreference?</span>
                            <i className="icon fas fa-chevron-down"></i>
                        </div>
                        <div className="faq_answer">
                            <span>Simply sign up for a free account on our website, AdsReference.com. Once registered, you can set up your website profile, define your target audience, and start exchanging advertising space with other sites.</span>
                        </div>
                    </div>
                    <div className="faq_container" onClick={(e) => afq(e.currentTarget)}>
                        <div className="faq_question">
                            <span>What types of websites can benefit from Adsreference?</span>
                            <i className="icon fas fa-chevron-down"></i>
                        </div>
                        <div className="faq_answer">
                            <span>Designed to benefit a wide range of websites, including small businesses, bloggers, content creators, startups, niche sites, online communities, educational platforms, and local service providers. If you're looking to increase online visibility, Adsreference is for you.</span>
                        </div>
                    </div>
                    <div className="faq_container" onClick={(e) => afq(e.currentTarget)}>
                        <div className="faq_question">
                            <span>How can I ensure that my ads are displayed on relevant websites?</span>
                            <i className="icon fas fa-chevron-down"></i>
                        </div>
                        <div className="faq_answer">
                            <span>Adsreference provides advanced targeting options, allowing you to choose specific categories and criteria for your campaigns. This ensures that your ads are displayed on websites that align with your content and target audience.</span>
                        </div>
                    </div>
                    <div className="faq_container" onClick={(e) => afq(e.currentTarget)}>
                        <div className="faq_question">
                            <span>Can I track the performance of my campaigns on Adsreference?</span>
                            <i className="icon fas fa-chevron-down"></i>
                        </div>
                        <div className="faq_answer">
                            <span>We offers a comprehensive dashboard with real-time analytics. You can monitor the performance of your campaigns, track impressions, clicks, and other relevant metrics, enabling you to optimize your advertising strategy.</span>
                        </div>
                    </div>
                    <div className="center">
                        <Link to={isLogin ? "/dashboard" : "/login"} onClick={window.localStorage.setItem("callback", "/dashboard")}><button>More Info</button></Link>
                    </div>
                </div>
            </section>
            <section className="more">
                <div className="more_content">
                    <p>100+ Already Joined</p>
                    <h1>Try us now and grow your projects for free</h1>
                    <Link to={isLogin ? "/dashboard" : "/login"} onClick={window.localStorage.setItem("callback", "/dashboard")}><button>Try Now</button></Link>
                </div>
            </section>
            <Footer />
        </>
    );
};
export default Index;