import { useEffect } from "react";
import Footer from "./layout/footer";
import Seo from "../../utils/Seo";

const Terms = () => {
    useEffect(() => {
        const scrollToTop = () => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        };

        setTimeout(scrollToTop, 0);
    }, []);

    return (
        <>
            <Seo
                dynamic={true}
                title={"Terms of use"}
                description={"Explore Adsreference's Terms of Use. Learn about the guidelines and rules for using Adsreference's services and features."}
            />

            <section className="terms">
                <div className="terms-content">
                    <h2>Terms and Conditions</h2>

                    <p>Welcome to Adsreference!</p>

                    <h3>1. Acceptance of Terms</h3>
                    <p>By using Adsreference, you agree to comply with and be bound by the following terms and conditions of use. If you do not agree to these terms, please do not use this website.</p>

                    <h3>2. Use of the Service</h3>
                    <p>Your use of Adsreference is at your sole risk. The service is provided on an "as is" and "as available" basis.</p>

                    <h3>3. User Accounts</h3>
                    <p>When you create an account with us, you must provide accurate and complete information. You are solely responsible for the activity that occurs on your account.</p>

                    <h3>4. Content</h3>
                    <p>Users may post content on Adsreference. By posting content, you grant us a non-exclusive, worldwide, royalty-free license to use, display, and distribute the content.</p>

                    <h3>5. Prohibited Conduct</h3>
                    <p>You agree not to engage in any of the following prohibited activities: violating laws, infringing on intellectual property rights, impersonating others, or engaging in harmful activities.</p>

                    <h3>6. Termination</h3>
                    <p>We reserve the right to terminate or suspend your account and access to the service for any reason, without notice.</p>

                    <h3>7. Changes to Terms</h3>
                    <p>We may revise these terms at any time. By using Adsreference after any changes to these terms, you agree to be bound by the revised terms.</p>

                    <h3>8. Contact Information</h3>
                    <p>If you have any questions about these terms, please contact us at contact@adsreference.com</p>

                    <p>These terms and conditions were last updated on 28/12/2023.</p>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default Terms;
