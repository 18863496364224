import React from 'react';
import { Helmet } from 'react-helmet';

export default function Seo({ dynamic, title, description, image }) {
    if (dynamic) {
        return (
            <Helmet>
                { /* Standard metadata tags */}
                <title>{title}</title>
                <meta name="author" content={title} />
                <meta name='description' content={description} />

                { /* End standard metadata tags */}
                { /* Facebook tags */}
                <meta name="og:site_name" content="Adsreference" />
                <meta name="og:url" content="location" />
                <meta property="og:image" content="https://i.imgur.com/s1Sml3i.png" />
                <meta property="og:type" content={"website"} />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                { /* End Facebook tags */}
                { /* Twitter tags */}
                {/* <meta name="twitter:creator" content={"@Join4Join_off"} /> */}
                <meta name="twitter:card" content={"website"} />
                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={description} />
                { /* End Twitter tags */}
                <meta name="apple-mobile-web-app-title" content={title} />
                <meta name="application-name" content={description} />
            </Helmet>
        )
    } else {
        return (
            <Helmet>
                { /* Standard metadata tags */}
                <title>Adsreference - Your Free Network for Effective Site Promotion</title>
                <meta name="author" content={"Adsreference - Your Free Network for Effective Site Promotion"} />
                <meta name='description' content={`Discover Adsreference, a powerful free network dedicated to enhancing site promotion. Elevate your online visibility and engage a wider audience effortlessly.`} />
                { /* End standard metadata tags */}
                { /* Facebook tags */}
                <meta name="og:site_name" content="Adsreference" />
                <meta name="og:url" content="location" />
                <meta property="og:image" content="https://i.imgur.com/s1Sml3i.png" />
                <meta property="og:type" content={"website"} />
                <meta property="og:title" content={`Adsreference - Your Free Network for Effective Site Promotion`} />
                <meta property="og:description" content={`Discover Adsreference, a powerful free network dedicated to enhancing site promotion. Elevate your online visibility and engage a wider audience effortlessly.`} />
                { /* End Facebook tags */}
                { /* Twitter tags */}
                {/* <meta name="twitter:creator" content={"@Join4Join_off"} /> */}
                <meta name="twitter:card" content={"website"} />
                <meta name="twitter:title" content={`Adsreference - Your Free Network for Effective Site Promotion`} />
                <meta name="twitter:description" content={`Discover Adsreference, a powerful free network dedicated to enhancing site promotion. Elevate your online visibility and engage a wider audience effortlessly.`} />
                { /* End Twitter tags */}
                <meta name="apple-mobile-web-app-title" content="Adsreference - Your Free Network for Effective Site Promotion" />
                <meta name="application-name" content="Discover Adsreference, a powerful free network dedicated to enhancing site promotion. Elevate your online visibility and engage a wider audience effortlessly." />
            </Helmet>
        )
    }
}