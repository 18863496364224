import { Link, useLocation } from "react-router-dom";
import me from "../../../api/methods/me";
import { useEffect } from "react";
import { useState } from "react";

const Nav_Dashboard = () => {
    const [user, setUser] = useState(undefined);
    const location = useLocation();

    const profileDropDown = () => {
        const profile = document.querySelector('.topbar .user_wrapper');
        const dropdownProfile = profile.querySelector('.profile-link');
        dropdownProfile.classList.toggle('show');
    }

    const burger = () => {
        const profile = document.querySelector('.topbar .user_wrapper');
        const dropdownProfile = profile.querySelector('.profile-link');
        dropdownProfile.classList.remove('show');

        let sidebar = document.querySelector(".sidebar");
        sidebar.classList.toggle("open");

        const topbar = document.querySelector(".topbar");
        topbar.classList.toggle("hide");

        const burger_ = document.querySelector('.burger');
        if (sidebar.classList.contains("open")) {
            burger_.classList.replace("bx-menu", "bx-menu-alt-right");

            if (window.innerWidth < 500) {
                document.getElementById("topbar").style.color = "white";
                document.getElementById("topbar").style.justifyContent = "flex-end";
                document.getElementById('topbar').style.padding = "1rem 0rem 1rem 1.5rem";
                document.getElementById('user_wrapper').style.display = "none";
            }
        } else {
            burger_.classList.replace("bx-menu-alt-right", "bx-menu");
            document.getElementById("topbar").style.color = "white";
            document.getElementById("topbar").style.justifyContent = "space-between";
            document.getElementById("topbar").style.padding = "1rem 1.5rem 1rem 1.5rem";
            document.getElementById('user_wrapper').style.display = "block";
        }
    }

    const logout = () => {
        window.location.replace("/");
        window.localStorage.removeItem("token")
    }

    const getMe = async () => {
        const request = await me();
        if (request && request.success === true) {
            setUser(request.data);
        } else {
            window.localStorage.setItem("callback", location.pathname);
            window.location.replace("/login");
        }
    }

    useEffect(() => {
        if (location.pathname.includes('/dashboard')) {
            getMe()
        }
    }, []);

    return (
        <>
            <div class="topbar" id="topbar">
                <div class="toggle" onClick={() => { burger() }}>
                    <i className="burger bx bx-menu" id="btn"></i>
                </div>
                <div class="user_wrapper" id="user_wrapper" onClick={() => { profileDropDown() }}>
                    <img src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fwww.cuisine-essentiel.fr%2Fimages%2F2020%2F10%2Favatar-neutre-300x300.png&f=1&nofb=1&ipt=d8ba35b4d95ccd7009e9b2423a32a26b0274c0b110991defc0947d28b83e78fb&ipo=images" alt="" />
                    <ul className="profile-link">
                        <li><Link to={"/dashboard/settings"}><i className='bx bxs-cog'></i>Settings</Link></li>
                        {
                            user && user.permissions && user.permissions === 1 ?
                                <li><Link to={"/dashboard/admin"}><i class='bx bxs-shield-minus'></i>Admin</Link></li>
                                : undefined
                        }
                        <li><a href="/" onClick={() => { logout() }}><i className='bx bxs-log-out-circle'></i>Logout</a></li>
                    </ul>
                </div>
            </div>
        </>
    );
};
export default Nav_Dashboard;