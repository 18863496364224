import request from '../request'

async function editWebsiteStatusAdmin(id, status) {
    return request('/website/edit/status', {
        method: 'POST',
        body: JSON.stringify({
            id, status
        })
    })
}

export default editWebsiteStatusAdmin