import request from '../request'

async function getSuccessStats(type) {
    return request('/website/all/success', {
        method: 'POST',
        body: JSON.stringify({
            type
        })
    })
}

export default getSuccessStats