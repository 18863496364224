import { BeatLoader } from 'react-spinners';
import React, { useState, useEffect } from 'react';

const Loading = () => {
    return (
        <BeatLoader color="#36D7B7" loading={true} size={15} />
    );
};

export default Loading;
