import { useEffect, useState } from "react";
import addWebsite from "../../api/methods/addWebsite";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate } from "react-router-dom";
import Seo from "../../utils/Seo";

const Add = () => {
    const navigate = useNavigate();
    const [step, setStep] = useState(1);
    const [website, setWebsite] = useState(undefined);

    const defaultAdDetails = {
        title: '',
        description: '',
        firstParagraphTitle: '',
        firstParagraph: '',
        secondParagraphTitle: '',
        secondParagraph: '',
        thirdParagraphTitle: '',
        thirdParagraph: '',
        urlRedirection: '',
        urlBanner: '',
        logo: '',
    }

    const ExampleAdDetails = {
        title: 'Adsreference - Boost Visibility',
        description: 'Free ad network for increased exposure.',
        firstParagraphTitle: 'Why Choose Adsreference?',
        firstParagraph: 'Unique platform for websites to collaborate and promote without cost. Enhance visibility and drive targeted traffic.',
        secondParagraphTitle: 'How It Works',
        secondParagraph: 'Create an account, set targeting preferences, and start exchanging ad space. Engage more for increased exposure.',
        thirdParagraphTitle: 'Unlock the Potential',
        thirdParagraph: "Don't miss the opportunity to expand your reach. Join Adsreference and be part of a thriving community.",
        urlRedirection: 'https://adsreference.com',
        urlBanner: 'https://i.imgur.com/LvltYQa.gif',
        logo: "https://i.imgur.com/5m0R0tB.png"
    };

    const [adDetails, setAdDetails] = useState(defaultAdDetails);

    const handleInputChangePreview = (field, value) => {
        setAdDetails((prevDetails) => ({
            ...prevDetails,
            [field]: value
        }));
    };

    const change = async () => {
        if (website) {
            setStep(2)
        } else {
            toast.error("You must provide an url.", {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        }
    }

    const saveChange = async () => {
        const isAnyFieldEmpty = Object.entries(adDetails).some(([key, value]) => value === '' && key !== 'urlBanner' && key != 'thirdParagraphTitle' && key != 'thirdParagraph');
        if (!isAnyFieldEmpty && website) {
            const res = await addWebsite(website, adDetails);
            if (res && res.success === true) {
                toast.loading('Adding website...', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });

                setTimeout(() => {
                    navigate(`/dashboard/site/${res.websiteUrl.split("//")[1]}`)
                }, 3000);
            } else if (res.message) {
                toast.error(res?.message, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
            }
        } else {
            toast.error("Please complete all the required fields of the form.", {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        }
    }

    useEffect(() => {
        const script = document.createElement("script")
        script.src = "https://www.google.com/recaptcha/api.js?render=6LepKBYpAAAAAOwtLmfQH9THIUmk9GweEuoQkNHu"
        document.body.appendChild(script)
    }, []);

    return (
        <>
            <Seo
                dynamic={true}
                title={"Adsreference Dashboard - add a new site for enhanced promotion"}
                description={"Add your site to Adsreference for free and supercharge its online presence. Join our network to enhance visibility, attract more visitors, and promote your site effectively."}
            />
            <section class="home_section">
                <ToastContainer
                    position="bottom-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="dark"
                />
                <div className="container_main">
                    {
                        step && step === 1 ? <>
                            <div className="modifications-block">
                                <div className="modifications-left">
                                    <h2>Add a website</h2>
                                    <input type="url" placeholder="https://adsreference.com"
                                        defaultValue={website}

                                        onInput={e => {
                                            const value = e.target.value;
                                            setWebsite(value);
                                        }} />
                                    <button onClick={() => change()}>Next</button>
                                </div>
                            </div>
                        </> : undefined
                    }
                    {
                        step && step === 2 ? <>
                            <div className="modifications-block">
                                <div className="modifications-right">
                                    <h2>Setup Advertising Details</h2>
                                    <h3 className="flex">Logo url: <span className="required">*required</span></h3>
                                    <input
                                        placeholder={adDetails.logo}
                                        value={adDetails.logo}
                                        onChange={(e) => handleInputChangePreview('logo', e.target.value)}
                                        type="url"
                                    />
                                    <h3 className="flex">Title: <span className="required">*required</span></h3>
                                    <input
                                        placeholder="AdsReference - Boost Visibility"
                                        value={adDetails.title}
                                        onChange={(e) => handleInputChangePreview('title', e.target.value)}
                                        maxLength={40}
                                    />
                                    <h3 className="flex">Slogan: <span className="required">*required</span></h3>
                                    <textarea className="slogan"
                                        placeholder="Free ad network for increased exposure."
                                        value={adDetails.description}
                                        onChange={(e) => handleInputChangePreview('description', e.target.value)}
                                        maxLength={100}
                                    />
                                    <h3 className="flex">First paragraph title: <span className="required">*required</span></h3>
                                    <input
                                        placeholder="Why Choose AdsReference?"
                                        value={adDetails.firstParagraphTitle}
                                        onChange={(e) => handleInputChangePreview('firstParagraphTitle', e.target.value)}
                                        maxLength={40}
                                    />
                                    <h3 className="flex">First paragraph: <span className="required">*required</span></h3>
                                    <textarea
                                        placeholder="Unique platform for websites to collaborate and promote without cost. Enhance visibility and drive targeted traffic."
                                        value={adDetails.firstParagraph}
                                        onChange={(e) => handleInputChangePreview('firstParagraph', e.target.value)}
                                        maxLength={120}
                                    />
                                    <h3 className="flex">Second paragraph title: <span className="required">*required</span></h3>
                                    <input
                                        placeholder="How It Works"
                                        value={adDetails.secondParagraphTitle}
                                        onChange={(e) => handleInputChangePreview('secondParagraphTitle', e.target.value)}
                                        maxLength={40}
                                    />
                                    <h3 className="flex">Second paragraph: <span className="required">*required</span></h3>
                                    <textarea
                                        placeholder="Create an account, set targeting preferences, and start exchanging ad space. Engage more for increased exposure."
                                        value={adDetails.secondParagraph}
                                        onChange={(e) => handleInputChangePreview('secondParagraph', e.target.value)}
                                        maxLength={120}
                                    />
                                    <h3>Third paragraph title:</h3>
                                    <input
                                        placeholder="Unlock the Potential"
                                        value={adDetails.thirdParagraphTitle}
                                        onChange={(e) => handleInputChangePreview('thirdParagraphTitle', e.target.value)}
                                        maxLength={40}
                                    />
                                    <h3>Third paragraph:</h3>
                                    <textarea
                                        placeholder="Don't miss the opportunity to expand your reach. Join AdsReference and be part of a thriving community."
                                        value={adDetails.thirdParagraph}
                                        onChange={(e) => handleInputChangePreview('thirdParagraph', e.target.value)}
                                        maxLength={120}
                                    />
                                    <h3>Url banner:</h3>
                                    <span>Max size (465x80)</span>
                                    <input
                                        placeholder="https://i.imgur.com/LvltYQa.gif"
                                        value={adDetails.urlBanner}
                                        onChange={(e) => handleInputChangePreview('urlBanner', e.target.value)}
                                        type="url"
                                    />
                                    <h3 className="flex">Url redirection: <span className="required">*required</span></h3>
                                    <input
                                        placeholder="https://adsreference.com"
                                        value={adDetails.urlRedirection}
                                        onChange={(e) => handleInputChangePreview('urlRedirection', e.target.value)}
                                        type="url"
                                    />
                                    <div className="button_list">
                                        <button onClick={() => { setStep(1) }}>Previous</button>
                                        <button onClick={() => saveChange()}>Save Website</button>
                                    </div>
                                </div>
                                <div>
                                    <div className="modifications-left preview">
                                        <h2>Advertising Preview</h2>
                                        <div className="ad_preview_website">
                                            <div className="header">
                                                <div className="buttons">
                                                    <div>
                                                        <a href="https://adsreference" target="_blank"><img className="logo" src="https://i.imgur.com/5m0R0tB.png" alt="Adsreference logo"></img></a>
                                                    </div>
                                                    <div>
                                                        <div className="cross"></div>
                                                    </div>
                                                </div>
                                                {
                                                    adDetails && adDetails.logo ?
                                                        <div className="logo">
                                                            <img src={adDetails.logo} alt="Your website logo" />
                                                        </div> : undefined
                                                }
                                                <div className="presentation_block">
                                                    <h1>{adDetails.title}</h1>
                                                    <p>{adDetails.description}</p>
                                                </div>
                                                <img alt="transition" className="transition" src={"https://i.imgur.com/RsqT9rn.png"} />
                                            </div>
                                            <div className="block">
                                                {
                                                    adDetails && adDetails.firstParagraphTitle && adDetails.firstParagraph ?
                                                        <div className="more_block">
                                                            <h3>{adDetails?.firstParagraphTitle}</h3>
                                                            <p>{adDetails?.firstParagraph}</p>
                                                        </div> : undefined
                                                }
                                                {
                                                    adDetails && adDetails.secondParagraphTitle && adDetails.secondParagraph ?
                                                        <div className="more_block">
                                                            <h3>{adDetails?.secondParagraphTitle}</h3>
                                                            <p>{adDetails?.secondParagraph}</p>
                                                        </div> : undefined
                                                }
                                                {
                                                    adDetails && adDetails.thirdParagraphTitle && adDetails.thirdParagraph ?
                                                        <div className="more_block">
                                                            <h3>{adDetails?.thirdParagraphTitle}</h3>
                                                            <p>{adDetails?.thirdParagraph}</p>
                                                        </div> : undefined
                                                }
                                                {
                                                    adDetails && adDetails.urlBanner ?
                                                        <div className="banner_block">
                                                            <a href={adDetails?.urlRedirection} target="_blank">
                                                                <img src={adDetails?.urlBanner} alt="banner of the ad" />
                                                            </a>
                                                        </div> : undefined
                                                }
                                            </div>
                                            <div className="footer">
                                                <a className="btn btn--wide spacing-top-sm" href={adDetails?.urlRedirection} target="_blank">Discover<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.7071 8.70711C17.0976 8.31658 17.0976 7.68342 16.7071 7.29289L10.3431 0.928932C9.95262 0.538408 9.31946 0.538408 8.92893 0.928932C8.53841 1.31946 8.53841 1.95262 8.92893 2.34315L14.5858 8L8.92893 13.6569C8.53841 14.0474 8.53841 14.6805 8.92893 15.0711C9.31946 15.4616 9.95262 15.4616 10.3431 15.0711L16.7071 8.70711ZM0 9H16V7H0V9Z" fill="white"></path></svg></a>
                                                <div className="footer_more">
                                                    <p>Powered by <a href="https://adsreference.com" target="_blank">adsreference.com</a></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modifications-left preview">
                                        <h2>Example for adsreference</h2>
                                        <div className="ad_preview_website">
                                            <div className="header">
                                                <div className="buttons">
                                                    <div>
                                                        <a href="https://adsreference" target="_blank"><img className="logo" src="https://i.imgur.com/5m0R0tB.png" alt="Adsreference logo"></img></a>
                                                    </div>
                                                    <div>
                                                        <div className="cross"></div>
                                                    </div>
                                                </div>
                                                {
                                                    ExampleAdDetails && ExampleAdDetails.logo ?
                                                        <div className="logo">
                                                            <img src={ExampleAdDetails.logo} alt="Your website logo" />
                                                        </div> : undefined
                                                }
                                                <div className="presentation_block">
                                                    <h1>{ExampleAdDetails.title}</h1>
                                                    <p>{ExampleAdDetails.description}</p>
                                                </div>
                                                <img alt="transition" className="transition" src={"https://i.imgur.com/RsqT9rn.png"} />
                                            </div>
                                            <div className="block">
                                                {
                                                    ExampleAdDetails && ExampleAdDetails.firstParagraphTitle && ExampleAdDetails.firstParagraph ?
                                                        <div className="more_block">
                                                            <h3>{ExampleAdDetails?.firstParagraphTitle}</h3>
                                                            <p>{ExampleAdDetails?.firstParagraph}</p>
                                                        </div> : undefined
                                                }
                                                {
                                                    ExampleAdDetails && ExampleAdDetails.secondParagraphTitle && ExampleAdDetails.secondParagraph ?
                                                        <div className="more_block">
                                                            <h3>{ExampleAdDetails?.secondParagraphTitle}</h3>
                                                            <p>{ExampleAdDetails?.secondParagraph}</p>
                                                        </div> : undefined
                                                }
                                                {
                                                    ExampleAdDetails && ExampleAdDetails.thirdParagraphTitle && ExampleAdDetails.thirdParagraph ?
                                                        <div className="more_block">
                                                            <h3>{ExampleAdDetails?.thirdParagraphTitle}</h3>
                                                            <p>{ExampleAdDetails?.thirdParagraph}</p>
                                                        </div> : undefined
                                                }
                                                {
                                                    ExampleAdDetails && ExampleAdDetails.urlBanner ?
                                                        <div className="banner_block">
                                                            <a href={ExampleAdDetails?.urlRedirection} target="_blank">
                                                                <img src={ExampleAdDetails?.urlBanner} alt="banner of the ad" />
                                                            </a>
                                                        </div> : undefined
                                                }
                                            </div>
                                            <div className="footer">
                                                <a className="btn btn--wide spacing-top-sm" href={ExampleAdDetails?.urlRedirection} target="_blank">Discover<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.7071 8.70711C17.0976 8.31658 17.0976 7.68342 16.7071 7.29289L10.3431 0.928932C9.95262 0.538408 9.31946 0.538408 8.92893 0.928932C8.53841 1.31946 8.53841 1.95262 8.92893 2.34315L14.5858 8L8.92893 13.6569C8.53841 14.0474 8.53841 14.6805 8.92893 15.0711C9.31946 15.4616 9.95262 15.4616 10.3431 15.0711L16.7071 8.70711ZM0 9H16V7H0V9Z" fill="white"></path></svg></a>
                                                <div className="footer_more">
                                                    <p>Powered by <a href="https://adsreference.com" target="_blank">adsreference.com</a></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                            : undefined
                    }
                </div>
                <div className="setup_middle">
                    <div className="form_add">
                        <h1>Website Rewards</h1>
                        <fieldset>
                            <strong>
                                <p>
                                    Integrate your website and configure the widget to start earning coins <i className="fa fa-coins" style={{ color: "#fbff60" }}></i> based on the clicks it generates. Utilize these coins to launch free campaigns for your website or convert them into monetary rewards. Additionally, you'll receive 50 free coins for each new website added and validated.
                                </p>
                            </strong>
                        </fieldset>
                    </div>
                </div>
            </section >
        </>
    );
};
export default Add;