import request from '../request'

async function editWebsiteDesired(id, desired_language, desired_category, desired_tags) {
    return request('/website/edit/desired', {
        method: 'POST',
        body: JSON.stringify({
            id, desired_language, desired_category, desired_tags
        })
    })
}

export default editWebsiteDesired