/**
 * Make a request to the API
 * @param {string} endpoint - the API endpoint to make a request to
 * @param {object} options - request options
 * @param {"GET" | "POST" | "PUT" | "PATCH" | "DELETE"} options.method - the HTTP method to use for the request
 * @param {*} options.body - the body of the request (only valid for POST, PUT and PATCH)
 * @param {string} options.contentType - the content type of the request body
 */

async function request(endpoint, options = {}) {
    const token = localStorage.getItem('token')
    endpoint = endpoint.startsWith('/') ? endpoint.slice(1, endpoint.length) : endpoint

    const headers = {}
    if (token) headers.Authorization = token
    if (options.contentType) headers['content-type'] = options.contentType
    else if (options.body) headers['content-type'] = 'application/json'
    headers['Set-Cookie'] = 'Partitioned';

    const body = options.body ?? undefined

    return (await fetch(`/api/${endpoint}`, {
        headers,
        method: options.method ?? 'GET',
        body
    })).json()
}

export default request