import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate, useLocation, Link } from "react-router-dom";
import Index from './website/landing_page';
import Login from './website/landing_page/login';
import Index_Dashboard from './website/dashboard';
import Settings from './website/dashboard/settings';
import Nav_Dashboard from './website/dashboard/layout/nav_dashboard';
import Nav_Landing from './website/landing_page/layout/nav_landing';
import Sidebar from './website/dashboard/layout/siderbar';
import Add from './website/dashboard/add';
import Site from './website/dashboard/site';
import Terms from './website/landing_page/terms';
import Privacy from './website/landing_page/privacy';
import NotFound from './website/landing_page/404';
import Campaign from './website/dashboard/campaign';
import Earning from './website/dashboard/earning';
import Admin from './website/dashboard/admin';

function Navigation() {
  const location = useLocation();

  if (location.pathname.startsWith("/dashboard")
    || location.pathname.includes('/add')
    || location.pathname.includes('/campaign')
    || location.pathname.includes('/earning')
    || location.pathname.includes('/site')
    || location.pathname.includes('/admin')
    || location.pathname.includes('/site/')
    || location.pathname.includes('/settings')) {
    return <><Nav_Dashboard /><Sidebar /></>
  }

  if (location.pathname === "/"
    || location.pathname.includes('/about')
    || location.pathname.includes('/features')
    || location.pathname.includes('/faq')
    || location.pathname.includes('/terms')
    || location.pathname.includes('/privacy')
    || location.pathname.includes('/login')) {
    return <Nav_Landing />
  }

  if (["/callback", "/callback/", "/login"].includes(location.pathname)) return;
  return <Nav_Landing />
}

function App() {
  function PageNotFound() {
    const location = useLocation();
    return (
      location.pathname !== "/login" && location.pathname !== "/callback" ? (
        <NotFound />
      ) : null
    )
  }

  useEffect(() => {
    const script = document.createElement("script")
    // script.src = "http://localhost:3000/widget.js?id=1703329097341230600"
    script.src = "https://adsreference.com/widget.js?id=1705685576064612600"
    document.body.appendChild(script)
  }, []);


  return (
    <div className="root">
      <div className="g-recaptcha" data-sitekey="6LcKKZAjAAAAAN6Rzp8wQU9cwWxi1TIoB9FH_h1E" data-size="invisible"></div>

      <BrowserRouter>
        <Navigation />
        <Routes>
          /** LANDING PAGE */
          <Route path="/" element={<Index />} />
          <Route path="/login" element={<Login />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />

          /** DASHBOARD PAGE */
          <Route path="/dashboard" element={<Index_Dashboard />} />
          <Route path="/dashboard/settings" element={<Settings />} />
          <Route path="/dashboard/add" element={<Add />} />
          <Route path="/dashboard/campaign" element={<Campaign />} />
          <Route path="/dashboard/earning" element={<Earning />} />
          <Route path="/dashboard/site/:id" element={<Site />} />
          <Route path="/dashboard/admin" element={<Admin />} />

          /** ROUTE PAGE */
          <Route
            path="*"
            element={<PageNotFound />}
          />
          <Route
            path="/close"
            element={
              <>
                {() => {
                  window.close();
                }}
              </>
            }
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;