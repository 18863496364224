import request from '../request'

async function editEmail(email) {
    return request('/user/edit/email', {
        method: 'POST',
        body: JSON.stringify({
            email
        })
    })
}

export default editEmail