import { Link } from "react-router-dom";
import ownerWebsite from "../../api/methods/ownerWebsite";
import { useEffect, useState } from "react";
import React from "react";
import ReactApexChart from "react-apexcharts";
import StatsChart from "./components/StatsChart";
import getAllStats from "../../api/methods/getAllStats";
import Seo from "../../utils/Seo";
import Loading from "./components/Loading";
import EarningsChart from "./components/EarningsChart";
import earningLogs from "../../api/methods/earningLogs";
import earningStripe from "../../api/methods/earningStripe";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import getSuccessStats from "../../api/methods/getSuccessStats";

const Earning = () => {
    const [website, setWebsite] = useState([]);
    const [coins, setCoins] = useState(undefined);
    const [websiteStats, setWebsiteStats] = useState([]);
    const [activeType, setActiveType] = useState('7');
    const [earning_logs, setEarningLogs] = useState([]);

    const fetchWebsites = async () => {
        const request = await ownerWebsite();
        if (request && request.success === true) {
            setWebsite(request.data);
            setCoins(request.coins)
        } else if (request && request.success === false) {
            window.localStorage.removeItem("token")
            window.location.replace("/");
        }
    }

    const fetchEarningLogs = async () => {
        const request = await earningLogs();
        if (request && request.success === true) {
            setEarningLogs(request.data);
        }
    }

    const getEarningStripe = async () => {
        const request = await earningStripe();
        if (request && request.success === true) {
        } else if (request && request.success === false) {
            toast.error(request?.message, {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        }
    }

    const handleButtonClick = (type) => {
        setActiveType(type);
        fetchWebsiteStats(type);
    };

    const fetchWebsiteStats = async (type) => {
        const request = await getSuccessStats(type);
        if (request && request.success === true) {
            setWebsiteStats(request.data);
        } else if (request && request.success === false) {
            setWebsiteStats(undefined)
        }
    }

    useEffect(() => {
        fetchWebsites();
        fetchWebsiteStats("7");
        fetchEarningLogs();
    }, []);

    return (
        <>
            <Seo
                dynamic={true}
                title={"Monetize Your Website Visibility"}
                description={"Unlock the potential of your website's visibility with Adsreference Earnings. Seamlessly convert your site's traffic into real revenue streams. Start monetizing your online presence today!"}
            />
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
            <section className="home_section">
                <div className="container_main">
                    <div className="modifications-block">
                        <div className="modifications-right">
                            <h2>Earning site(s)</h2>
                            <div class="table-container">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Site</th>
                                            <th>Earnings status</th>
                                            <th>Cumulative earnings</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            website && website.length > 0 ?
                                                website.length > 0 &&
                                                website.map((websiteInfo) => {
                                                    return (
                                                        <>
                                                            <tr>
                                                                <td>{websiteInfo.url?.split('//')[1].split('/')[0]}</td>
                                                                <td>{websiteInfo && websiteInfo.status === "success" ? "Active" : "Incomplete installation"}</td>
                                                                <td>{websiteInfo && websiteInfo.status === "success" ? (Number(websiteInfo.coins) / 100).toFixed(2) + "$" : <i class="fa fa-warning" style={{ color: "#ffa600" }}></i>}</td>
                                                            </tr>
                                                        </>
                                                    );
                                                }) : <Loading />
                                        }
                                        <tr className="total">
                                            <td>Total</td>
                                            <td></td>
                                            <td>{coins ? (Number(coins) / 100).toFixed(2) : coins}$</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="modifications-left">
                            <h2>Reedem money</h2>
                            <p>For active sites, earnings are generated seamlessly. Ensure active status for revenue generation. You can redeem money after reaching 20$ minimum. Contact us promptly for any issues encountered.</p>
                            <div className="button_list">
                                <button onClick={() => { getEarningStripe() }}>Redeem money</button>
                                <a href="mailto:contact@adsreference.com"><button>Contact Us</button></a>
                            </div>
                        </div>
                    </div>
                    <div className="stats-block">
                        <h2>Earning statistics</h2>
                        <div className="stats">
                            <div>
                                <p>Total Earning: {websiteStats ? (Number(websiteStats.totalClicks) / 100).toFixed(2) : undefined}$</p>
                            </div>
                            <div className="filter">
                                <button
                                    onClick={() => handleButtonClick('7')}
                                    style={{ backgroundColor: activeType === '7' ? '#007bff' : 'gray' }}>
                                    7d
                                </button>
                                <button
                                    onClick={() => handleButtonClick('14')}
                                    style={{ backgroundColor: activeType === '14' ? '#007bff' : 'gray' }}>
                                    14d
                                </button>
                                <button
                                    onClick={() => handleButtonClick('30')}
                                    style={{ backgroundColor: activeType === '30' ? '#007bff' : 'gray' }}>
                                    30d
                                </button>
                            </div>
                        </div>
                        <EarningsChart websiteStats={websiteStats} />
                    </div>
                    <div className="modifications-right">
                        <h2>History transaction(s)</h2>
                        <div class="table-container">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Amount</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        earning_logs && earning_logs.length > 0 ?
                                            earning_logs.length > 0 &&
                                            earning_logs.map((logsInfo) => {
                                                return (
                                                    <>
                                                        <tr className="total">
                                                            <td>{logsInfo.createdAt}</td>
                                                            <td>{logsInfo.amount}$</td>
                                                            <td>{logsInfo.status}</td>
                                                        </tr>
                                                    </>
                                                );
                                            }) : <p>No transaction recorded.</p>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};
export default Earning;