import React from 'react';

function CategoryOptions({ siteCount }) {
    const categories = [
        { value: 'advertising', label: 'Advertising' },
        { value: 'hosting', label: 'Hosting' },
        { value: 'anime', label: 'Anime' },
        { value: 'art', label: 'Art' },
        { value: 'bot', label: 'Bot' },
        { value: 'community', label: 'Community' },
        { value: 'developer', label: 'Developer' },
        { value: 'fashion', label: 'Fashion' },
        { value: 'fitness', label: 'Fitness' },
        { value: 'food', label: 'Food' },
        { value: 'gaming', label: 'Gaming' },
        { value: 'movies', label: 'Movies' },
        { value: 'music', label: 'Music' },
        { value: 'roleplay', label: 'Roleplay' },
        { value: 'seo', label: 'SEO' },
        { value: 'socialmedia', label: 'Social Media' },
        { value: 'support', label: 'Support' },
        { value: 'technology', label: 'Technology' },
        { value: 'travel', label: 'Travel' },
        { value: 'videogames', label: 'Video Games' },
        { value: 'webdesign', label: 'Web Design' },
    ];

    return (
        <optgroup label="Category">
            {categories.map(({ value, label }) => (
                <option key={value} value={value}>
                    {
                        siteCount ?
                            <>
                                {label} ({siteCount?.[value] ?? 0} site{siteCount?.[value] > 1 && 's'})
                            </>
                            :
                            <>
                                {label}
                            </>
                    }
                </option>
            ))}
        </optgroup>
    );
}

export default CategoryOptions;
