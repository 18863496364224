import React, { useState, useEffect } from 'react';

function TagsOption({ tags, onClose, onSelectTags, selectedTagsOutside }) {
    const [modalOpen, setModalOpen] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedTags, setSelectedTags] = useState([]);

    useEffect(() => {
        setSelectedTags(selectedTagsOutside);
    }, [selectedTagsOutside]);

    const closeModal = () => {
        setModalOpen(false);
        onClose();
    };

    const handleTagClick = (tag) => {
        const index = selectedTags?.indexOf(tag);
        if (index === -1 && selectedTags.length < 10) {
            setSelectedTags([...selectedTags, tag]);
        } else if (index !== -1) {
            const updatedTags = selectedTags?.filter(selectedTag => selectedTag !== tag);
            setSelectedTags(updatedTags);
        }
    };

    const handleConfirmSelection = () => {
        const combinedTags = [...selectedTags];
        const uniqueTags = Array?.from(new Set(combinedTags));
        const limitedTags = uniqueTags?.slice(0, 10);
        onSelectTags(limitedTags);
        closeModal();
    };

    const filteredTags = tags && Array.isArray(tags)
        ? tags?.filter(tag => tag && !selectedTags.includes(tag) && tag.tag.toLowerCase().includes(searchTerm.toLowerCase()))
        : [];

    return (
        <>
            {modalOpen && (
                <div className="modal-overlay" onClick={closeModal}>
                    <div className="modal" onClick={e => e.stopPropagation()}>
                        <h2>Tags</h2>
                        <input
                            type="text"
                            placeholder="Search tags..."
                            value={searchTerm}
                            onChange={e => setSearchTerm(e.target.value)}
                        />
                        <ul>
                            {filteredTags?.map(({ tag, siteCount }) => (
                                <li
                                    key={tag}
                                    className={selectedTags.includes(tag) ? 'selected' : ''}
                                    onClick={() => handleTagClick(tag)}
                                >
                                    {tag} ({siteCount} {siteCount !== 1 ? 'uses' : 'use'})
                                </li>
                            ))}
                        </ul>
                        <div className='button_list'>
                            <button className='close' onClick={closeModal}>Close</button>
                            <button className='confirm' onClick={handleConfirmSelection}>Confirm</button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default TagsOption;
