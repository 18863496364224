import request from '../request'

async function getAd(id) {
    return request('/ad/get', {
        method: 'POST',
        body: JSON.stringify({
            id
        })
    })
}

export default getAd