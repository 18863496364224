import request from '../request'

/**
 * Login with Discord
 * @param {string} code - the Discord authorization code
 */

async function login_discord(code, type) {
    return request('/login', {
        method: 'POST',
        body: JSON.stringify({
            code, type
        })
    })
}

export default login_discord