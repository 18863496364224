import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <>
            <div className="footer-basic">
                <footer>
                    <div className="social">
                        <a href="#"><i className="icon fab fa-discord"></i></a>
                    </div>
                    <div className="brand">
                        <img src="https://i.imgur.com/5m0R0tB.png" alt="Adsreference Logo" />
                        <p>Adsreference is your platform for free site promotion. Join us to boost your online presence!</p>
                    </div>
                    <hr />
                    <ul className="list-inline">
                        <li className="list-inline-item"><Link to={"/"}>Home</Link></li>
                        <li className="list-inline-item"><Link to={"/privacy"}>Privacy Policy</Link></li>
                        <li className="list-inline-item"><Link to={"/terms"}>Terms of Service</Link></li>
                    </ul>
                    <p className="copyright">Adsreference copyright &copy; 2024 All Rights Reserved.</p>
                </footer>
            </div>
        </>
    );
};

export default Footer;
