import { useEffect } from "react";
import Footer from "./layout/footer";
import Seo from "../../utils/Seo";

const Privacy = () => {
    useEffect(() => {
        const scrollToTop = () => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        };

        setTimeout(scrollToTop, 0);
    }, []);

    return (
        <>
            <Seo
                dynamic={true}
                title={"Privacy policy"}
                description={"Explore Adsreference's Privacy Policy. Learn about how Adsreference collects, uses, and protects your personal information."}
            />
            <section className="privacy">
                <div className="privacy-content">
                    <h2>Privacy Policy</h2>
                    <p>Your privacy is important to us at Adsreference. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our website.</p>

                    <h3>Information We Collect</h3>
                    <p>We may collect personal and non-personal information when you interact with our website. Personal information may include your name, email address, and other details you provide voluntarily.</p>

                    <h3>How We Use Your Information</h3>
                    <p>We use the information we collect to provide and improve our services. We may also use your information to communicate with you, customize content, and analyze how our services are used.</p>

                    <h3>Sharing Your Information</h3>
                    <p>We do not sell, trade, or rent your personal information to third parties. We may share aggregated and non-personal information for various purposes.</p>

                    <h3>Cookies</h3>
                    <p>Adsreference uses cookies to enhance your experience. You can control cookies through your browser settings.</p>

                    <h3>Security</h3>
                    <p>We take reasonable measures to protect your information, but no method of transmission over the Internet or electronic storage is 100% secure. We strive to use commercially acceptable means to protect your personal information.</p>
                    <br />
                    <h3>Contact Information</h3>
                    <p>If you have any questions about this privacy policy, please contact us at contact@adsreference.com</p>
                    <br />
                    <p>This Privacy Policy is effective as of 28/12/2023. We reserve the right to update or change our Privacy Policy at any time, and you should check this Privacy Policy periodically. Your continued use of the service after we post any modifications to the Privacy Policy on this page will constitute your acknowledgment of the modifications and your consent to abide and be bound by the modified Privacy Policy.</p>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default Privacy;
